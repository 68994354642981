import styled from 'styled-components/native'

const ToursContentWrapper = styled.View`
  flex: 1;
  width: 95%;
  margin: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-top: 0;
`

const StyledButtonWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const StyledModeSwitchWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const CartBatchCardContentWrapper = styled.View`
  width: 100%;
  /*
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  */
`

const TextInputWrapper = styled.View`
  width: 65%;
  align-self: center;
`

export {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
  CartBatchCardContentWrapper,
  TextInputWrapper,
}
