import {
  getActionFromState,
  getPathFromState,
  getStateFromPath,
  NavigationAction,
  NavigationState,
  PartialState,
  PathConfigMap,
} from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { navigationRef } from '../../navigation/RootNavigation'
import StorageUtil from '../storage/storage'

interface AppStackParamList {
  Authentication: undefined
  ShelfLabelInfos: undefined
  ClientCatalogTours: undefined
  ClientMainNavigation: undefined
  ProducerMainNavigation: undefined
  CarrierMainNavigation: undefined
  UserCarrierConfigurationNav: undefined
  UserClientConfigurationNav: undefined
  UserProducerConfigurationNav: undefined
  UserMenu: undefined
  Account: undefined
  EditInvoice: undefined
  ListInvoice: undefined
  CreateInvoice: undefined
  CarrierBilling: undefined
  ClientBilling: undefined
  InvoiceStatementList: undefined
}

type Options<ParamList extends {}> = {
  screens: PathConfigMap<ParamList>
}

const config: Options<AppStackParamList> = {
  screens: {
    Authentication: {
      screens: {
        RegistrationLanding: {
          path: 'register-invitation',
          parse: {
            token: String,
          },
        },
        ResetPassword: {
          path: 'reset-password',
          parse: {
            token: String,
            email: String,
          },
        },
        RegisterLanding: {
          path: 'register-landing',
          parse: undefined,
        },
      },
    },

    ShelfLabelInfos: {
      path: 'shelf-label-infos',
      parse: {
        id: String,
      },
    },

    CarrierMainNavigation: {
      screens: {
        CarrierBilling: {
          screens: {
            CreateInvoice: {
              path: 'carrier/invoice/create',
            },
            EditInvoice: {
              path: 'carrier/invoice/edit/:invoiceId',
              parse: {
                invoiceId: (invoiceId: string) => `${invoiceId}`,
              },
            },
            ListInvoice: {
              path: 'carrier/invoice/list',
            },
            CarrierBillingDeliveryNotes: {
              path: 'carrier/billing-delivery-notes',
              parse: {
                page: Number,
                pageSize: Number,
                mode: Number,
              },
            },
          },
        },
      },
    },
    ClientMainNavigation: {
      screens: {
        ClientCatalog: {
          screens: {
            ClientCatalogTours: {
              path: 'client-catalog-tours',
              parse: {
                auth_token: String,
              },
            },
            ClientCatalog: {
              path: 'client-catalog',
              parse: undefined,
            },
            ClientBatchPage: {
              path: 'client-batch-page/:id',
              parse: {
                id: (id: string) => `${id}`,
              },
            },
          },
        },
        ClientOrders: {
          path: 'client-orders',
          parse: undefined,
        },
        ClientBilling: {
          screens: {
            InvoiceStatementList: {
              path: 'client/invoice-statement/list',
            },
          },
        },
      },
    },
    ProducerMainNavigation: {
      screens: {
        ProducerBilling: {
          screens: {
            ProducerOrderList: {
              path: 'producer/order/list',
            },
            ProducerDirectOrder: {
              path: 'producer/order/:orderId',
              parse: {
                orderId: (orderId: string) => `${orderId}`,
              },
            },
            ProducerDirectCartAdd: {
              path: 'producer/direct-cart/add',
            },
            ProducerDirectCartUpdate: {
              path: 'producer/direct-cart/update',
            },
            ProducerDirectCartCatalog: {
              path: 'producer/direct-cart/catalog',
            },
            ProducerBillingPaymentConfiguration: {
              path: 'producer-billing-configuration',
              parse: undefined,
            },
            CreateInvoice: {
              path: 'producer/invoice/create',
            },
            EditInvoice: {
              path: 'producer/invoice/edit/:invoiceId',
              parse: {
                invoiceId: (invoiceId: string) => `${invoiceId}`,
              },
            },
            ListInvoice: {
              path: 'producer/invoice/list',
            },
            InvoiceStatementList: {
              path: 'producer/invoice-statement/list',
            },
          },
        },
      },
    },
    UserCarrierConfigurationNav: {
      screens: {
        Account: {
          path: 'account/info/billing/carrier',
          parse: {
            id: String,
          },
        },
      },
    },
    UserProducerConfigurationNav: {
      screens: {
        Account: {
          path: 'account/info/billing/producer',
          parse: {
            id: String,
          },
        },

        CreateInvoice: {
          path: 'account/producer/invoice/create',
        },
        EditInvoice: {
          path: 'account/producer/invoice/edit/:invoiceId',
          parse: {
            invoiceId: (invoiceId: string) => `${invoiceId}`,
          },
        },
        ListInvoice: {
          path: '/account/producer/invoice/list',
        },
      },
    },
    UserClientConfigurationNav: {
      screens: {
        Account: {
          path: 'account/info/billing/client',
          parse: {
            id: String,
          },
        },
      },
    },
  },
}

export const DeepLinkSchema = __DEV__ ? Linking.createURL('/') : 'https://staging.harvy.tech/'

export const linking = {
  prefixes: [Linking.createURL('/'), 'https://app.harvy.tech/', 'https://staging.harvy.tech/'],
  config,
}

const cleanPathStr = (path: string) => {
  if (!path) {
    return ''
  }

  const queryVairablesIndex = path.indexOf('?')
  if (queryVairablesIndex === -1) {
    return path
  }
  return path.substr(0, queryVairablesIndex)
}

export const checkDeepLinkResult = async (url: string, logout: () => void) => {
  const { path, queryParams } = Linking.parse(url)

  if (queryParams && queryParams.auth_token && queryParams.profile_id) {
    // await logout()

    await StorageUtil.setItem('tempToken', queryParams.auth_token)
    await StorageUtil.setItem('profileId', queryParams.profile_id)
    await StorageUtil.setItem('account_id', queryParams.id)
  }

  if (!path) {
    return { didDeepLinkLand: true }
  }

  const currentState = navigationRef.current?.getRootState() as NavigationState
  if (!currentState) {
    return { didDeepLinkLand: true }
  }
  const linkState = getStateFromPath(path, config) as PartialState<NavigationState>
  if (!linkState) {
    return { didDeepLinkLand: true }
  }
  const currentPath = cleanPathStr(getPathFromState(currentState))
  const linkPath = cleanPathStr(getPathFromState(linkState))
  const action = getActionFromState(linkState) as NavigationAction

  return {
    action,
    linkPath,
    didDeepLinkLand: currentPath === linkPath,
  }
}
