import * as formatFNS from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import { ClientTypeEnum, DeliveryNoteTypeEnum, ProductEnums, UserEnums } from '../../../enums'
import { Address, Producer, Group, Tour, ProductLabel, Stop, Batch } from '../../domain'
import { categoryWithNbProducts } from '../../modules/Cart/CartTourClientCard/CartTourClientCard.model'
import { GroupEnums } from '../../../enums/group'
import AccountUtil from './account'

const formatPrice = (
  price?: any,
  unit?: 'ht' | 'ttc',
  producer?: Producer,
  roundThousands?: boolean,
  removeDecimals?: boolean,
) => {
  if (!price && price !== 0) return ''
  let tempPrice = Number(price)

  if (typeof tempPrice !== 'number') {
    tempPrice = parseFloat(price.replace(',', '.'))
  }

  if (removeDecimals && Number.isInteger(tempPrice)) {
    return `${tempPrice}€${getPriceUnits(unit, producer)}`
  }

  let formattedPrice: string
  if (roundThousands && tempPrice >= 1000) {
    formattedPrice = `${(Math.round(tempPrice * 100) / 100000).toFixed(2)}k`
  } else {
    formattedPrice = (Math.round(tempPrice * 100) / 100).toFixed(2)
  }

  return `${formattedPrice.toString().replace('.', ',')}€${getPriceUnits(unit, producer)}`
}

const getPriceUnits = (unit?: 'ht' | 'ttc', producer?: Producer) => {
  let unitFormatted = ''
  if (producer?.account && !AccountUtil.isProfileSubjectToVAT(producer.account)) {
    unitFormatted = 'TTC'
  } else if (unit && unit === 'ht') {
    unitFormatted = 'HT'
  } else if (unit && unit === 'ttc') {
    unitFormatted = 'TTC'
  }
  return unitFormatted
}

const formatUnity = (unity?: ProductEnums.MESURE_TYPE) => {
  if (!unity) return 'NA'

  if (unity === ProductEnums.MESURE_TYPE.KILO) {
    return 'kg'
  }
  if (unity === ProductEnums.MESURE_TYPE.PIECE) {
    return 'pcs'
  }
  return 'NA'
}

const capitalize = (word: string) => {
  if (!word || typeof word !== 'string' || word.length < 1) return ''

  return word.charAt(0).toUpperCase() + word.slice(1)
}

const formatPercentage = (value: number) => {
  if ((!value && value !== 0) || typeof value !== 'number') return ''

  return `${(Math.floor(value * 10000) / 100).toString().replace('.', ',')}%`
}

const numberToPercentage = (value: number) => {
  return (value * 10000) / 100
}

const formatQuantity = (
  valueIn?: number | string,
  unit?: ProductEnums.MESURE_TYPE,
  longUnits?: boolean,
) => {
  if ((!valueIn && valueIn !== 0) || !unit) return ''
  let value = valueIn
  if (typeof value !== 'number') {
    value = parseFloat(valueIn as string)
  }

  const valueFormatted = value
    .toFixed(3)
    .replace(/(\.0+|0+)$/, '')
    .replace('.', ',')

  if (unit === ProductEnums.MESURE_TYPE.KILO) {
    return `${valueFormatted}kg`
  }
  if (unit === ProductEnums.MESURE_TYPE.PIECE) {
    return `${valueFormatted}${longUnits ? ' pièces' : 'pcs'}`
  }
  return 'NA'
}

const displayCarrierFees = (value: string | number | null | undefined): string => {
  if (value === null || value === undefined || value === '') return ''
  const numValue = typeof value === 'string' ? parseFloat(value) : value
  return Math.round(numValue * 100).toString()
}

const convertCarrierFeesToDecimal = (value: string): number => {
  if (!value) return 0
  return parseInt(value, 10) / 100
}

const formatProductLabels = (productLabels: ProductLabel[] | undefined): string => {
  if (!productLabels || productLabels.length === 0) {
    return '-'
  }
  const labels = productLabels.map((productLabel) => productLabel.label)
  return labels.join(', ')
}

const minutesFromDate = (date: Date) => {
  const minutes = formatFNS.format(date, 'mm', { locale: dateFnsLocales.fr, weekStartsOn: 1 })
  if (minutes !== '00') {
    return minutes
  }
  return ''
}

const startOfWeek = (date: Date) => {
  if (date.getDay() === 0) {
    return date.getDate() - date.getDay() - 6
  }
  return date.getDate() - date.getDay() + 1
}

const startOfWeekDate = (date: Date) => {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  if (date.getDay() === 0) {
    return new Date(date).setDate(date.getDate() - date.getDay() - 6)
  } else {
    return new Date(date).setDate(date.getDate() - date.getDay() + 1)
  }
}

/*
const startOfWeekDate = (date: Date) => {
  if (date.getDay() === 0) {
    return new Date(date).setDate(date.getDate() - 6)
  }
  return new Date(date).setDate(date.getDate() - date.getDay() + 1)
}
*/

export type dateFormat =
  | 'FullDate+StartTime'
  | 'PartialDate+StartTime'
  | 'Date+StartTime'
  | 'FullDate+StartTime+EndTime'
  | 'StartTime+EndTime'
  | 'FullDate'
  | 'Date'
  | 'DateShort'
  | 'Time'
  | 'InputDate'
  | 'InputTime'
  | 'MonthYear'
  | 'DateFullYear'
  | 'ChoppedMonth'
  | 'PartialDate'
  | 'Week'
  | 'WeekDay'
  | 'WeekDay+Time'

const formatDate = (value?: string, format?: dateFormat, value2?: string) => {
  if (!value || typeof value !== 'string' || !format) return 'NA'

  const date: Date = new Date(value)
  let date2: Date | undefined = new Date(value)

  if (value2 && typeof value2 === 'string') {
    date2 = new Date(value2)
  }

  if (!date) {
    return 'NA'
  }

  let tempResult = ''

  if (format === 'FullDate+StartTime') {
    tempResult = `${capitalize(
      formatFNS.format(date, 'EEEE', {
        locale: dateFnsLocales.fr,
      }),
    )} ${formatFNS.format(date, 'd MMMM - HH', {
      locale: dateFnsLocales.fr,
    })}h${minutesFromDate(date)}`
  }
  if (format === 'PartialDate+StartTime') {
    tempResult = `${capitalize(
      formatFNS.format(date, 'EEEE', {
        locale: dateFnsLocales.fr,
      }),
    )} ${formatFNS.format(date, 'dd/MM - HH', {
      locale: dateFnsLocales.fr,
    })}h${minutesFromDate(date)}`
  }
  if (format === 'PartialDate') {
    tempResult = `${capitalize(
      formatFNS.format(date, 'EE', {
        locale: dateFnsLocales.fr,
      }),
    )} ${formatFNS.format(date, 'dd/MM', {
      locale: dateFnsLocales.fr,
    })}`
  }
  if (format === 'Date+StartTime') {
    tempResult = `${formatFNS.format(date, 'dd/MM/yy - HH', {
      locale: dateFnsLocales.fr,
    })}h${minutesFromDate(date)}`
  }
  if (format === 'FullDate+StartTime+EndTime') {
    tempResult = `${capitalize(
      formatFNS.format(date, 'EEEE', {
        locale: dateFnsLocales.fr,
      }),
    )} ${formatFNS.format(date, 'd MMMM - HH', {
      locale: dateFnsLocales.fr,
    })}h${formatFNS.format(date, 'mm', { locale: dateFnsLocales.fr })} à ${formatFNS.format(
      date2,
      'HH',
      { locale: dateFnsLocales.fr },
    )}h${formatFNS.format(date2, 'mm', { locale: dateFnsLocales.fr })}`
  }
  if (format === 'StartTime+EndTime') {
    tempResult = `${formatFNS.format(date, 'H', {
      locale: dateFnsLocales.fr,
    })}h${formatFNS.format(date, 'mm', { locale: dateFnsLocales.fr })} à ${formatFNS.format(
      date2,
      'HH',
      { locale: dateFnsLocales.fr },
    )}h${formatFNS.format(date2, 'mm', { locale: dateFnsLocales.fr })}`
  }
  if (format === 'FullDate') {
    tempResult = `${capitalize(
      formatFNS.format(date, 'EEEE', {
        locale: dateFnsLocales.fr,
      }),
    )} ${formatFNS.format(date, 'd MMMM', { locale: dateFnsLocales.fr })}`
  }

  if (format === 'WeekDay') {
    tempResult = capitalize(`${formatFNS.format(date, 'EEEE', { locale: dateFnsLocales.fr })}`)
  }
  if (format === 'Date') {
    tempResult = `${formatFNS.format(date, 'dd/MM/yy', { locale: dateFnsLocales.fr })}`
  }
  if (format === 'DateFullYear') {
    tempResult = `${formatFNS.format(date, 'dd/MM/yyyy', { locale: dateFnsLocales.fr })}`
  }
  if (format === 'DateShort') {
    tempResult = `${formatFNS.format(date, 'dd/MM', { locale: dateFnsLocales.fr })}`
  }
  if (format === 'Time') {
    tempResult = `${formatFNS.format(date, 'HH', { locale: dateFnsLocales.fr })}h${formatFNS.format(
      date,
      'mm',
      { locale: dateFnsLocales.fr },
    )}`
  }
  if (format === 'InputDate') {
    tempResult = `${formatFNS.format(date, 'yyyy-MM-dd', { locale: dateFnsLocales.fr })}`
  }
  if (format === 'InputTime') {
    tempResult = `${formatFNS.format(date, 'HH:mm', { locale: dateFnsLocales.fr })}`
  }

  if (format === 'MonthYear') {
    tempResult = capitalize(`${formatFNS.format(date, 'MMMM yyyy', { locale: dateFnsLocales.fr })}`)
  }

  if (format === 'ChoppedMonth') {
    tempResult = `${formatFNS.format(date, 'MMM yy', { locale: dateFnsLocales.fr })}`
  }

  if (format === 'Week') {
    tempResult = `S${formatFNS.format(date, 'ww', { locale: dateFnsLocales.fr, weekStartsOn: 1 })}`
  }

  if (format === 'WeekDay+Time') {
    tempResult = `${capitalize(
      formatFNS.format(date, 'EEEE', {
        locale: dateFnsLocales.fr,
      }),
    )} ${formatFNS.format(date, 'H', {
      locale: dateFnsLocales.fr,
    })}h${formatFNS.format(date, 'mm', { locale: dateFnsLocales.fr })}`
  }

  if (tempResult === '') {
    return 'NA'
  }

  return tempResult
}

const stringToFloat = (value?: string) => {
  if (!value || value === '') {
    return Number(0.0)
  }
  return Number(value.replace(',', '.').replace(' ', ''))
}

const floatToString = (value?: number) => {
  if (!value || value === 0) {
    return ''
  }
  return value.toString().replace('.', ',')
}

const formatId = (value?: string) => {
  if (value && value.length > 5) {
    return value.slice(-5)
  }
  return 'NA'
}

const formatVolume = (value?: any, withUnity?: boolean) => {
  if (!value && value !== 0) return 'NA'
  let tempValue = value
  if (typeof tempValue !== 'string') {
    tempValue = parseFloat(value)
  }
  tempValue = tempValue / 1000000
  tempValue = (Math.round(tempValue * 100) / 100).toFixed(2)
  return `${tempValue.replace('.', ',')}${withUnity ? ' m3' : ''}`
}

const formatDouble = (value?: any): string => {
  if (!value && value !== 0) return 'NA'
  let tempValue = value
  if (typeof tempValue !== 'string') {
    tempValue = parseFloat(value)
  }
  tempValue = (Math.round(tempValue * 100) / 100).toFixed(2)
  return `${tempValue.replace('.', ',')}`
}

const getLabelFromDeliveryNoteType = (type?: DeliveryNoteTypeEnum) => {
  let label = ''
  if (type === 1) {
    label = 'Producteur <> Client'
  }
  if (type === 2) {
    label = 'Super Producteur <> Client'
  }
  if (type === 3) {
    label = 'Producteur <> Super Producteur'
  }
  return label
}

const getLabelFromClientType = (value?: ClientTypeEnum) => {
  let label = ''
  if (value === ClientTypeEnum.RETAILER) {
    label = 'client'
  }

  if (value === ClientTypeEnum.WHOLESALER) {
    label = 'grossiste'
  }

  return label
}

const getCarrierNamingFromGroupType = (groupType: GroupEnums.GroupTypeEnum | undefined) => {
  if (!groupType) {
    return 'NA'
  }
  return groupType === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS
    ? 'Producteur-Livreur'
    : 'Super-Producteur'
}

const cleanEmail = (value?: string) => {
  if (!value || value === '') {
    return ''
  }
  return value.replace(' ', '').toLowerCase()
}

const getTourTarget = (tour?: Tour, type?: 'short' | 'long' | 'targets' | 'targetLong') => {
  if (!tour) {
    return ''
  }

  const tourTargetLength = tour?.target?.length
  const targetMaxLength = type === 'targetLong' ? 30 : 25
  const tourTargetFormatted = tour?.target
    ? `${tour?.target?.slice(
        0,
        tourTargetLength > targetMaxLength ? targetMaxLength - 1 : tourTargetLength,
      )}${tourTargetLength > targetMaxLength ? '...' : ''}`
    : ''
  const city = tour.city
  if (!city) {
    return tourTargetFormatted
  }
  if (!type || type === 'short') {
    return city.label
  }
  if (type === 'targets' || type === 'targetLong') {
    return `${city.label} ${
      tourTargetFormatted && tourTargetFormatted !== city.label ? `(${tourTargetFormatted})` : ''
    }`
  }
  if (tourTargetFormatted) {
    return `${city.label} (${tourTargetFormatted})`
  }
  return city.label
}

const pluralizeAmountLabel = (label: string, amount: number, withoutAmountDisplay?: boolean) => {
  if (amount === undefined) {
    return `0 ${label}`
  } else if (withoutAmountDisplay === true) {
    return `${label}${amount > 1 ? 's' : ''}`
  }
  return `${amount} ${label}${amount > 1 ? 's' : ''}`
}

const pluralizeLabel = (label: string) => {
  if (label.endsWith('s')) {
    return label
  }
  return `${label}s`
}

const moreCategories = (categories: categoryWithNbProducts[] | undefined, isMobile?: boolean) => {
  if (!categories || categories.length < 1) return ''

  if (isMobile) {
    return pluralizeAmountLabel('catégorie', categories.length)
  }

  const orderedCategories = categories.sort(
    (categoryA, categoryB) => categoryB.nbProducts - categoryA.nbProducts,
  )

  if (orderedCategories.length > 2) {
    return `${orderedCategories[0].label}, ${orderedCategories[1].label} et plus`
  }

  return `${orderedCategories[0].label}${
    orderedCategories[1] ? `, ${orderedCategories[1].label}` : ''
  }`
}

const formatCities = (cities: string[] | undefined): string => {
  const MAX_DISPLAY_CITIES = 2

  const uniqueCities = cities?.filter((city, index) => cities.indexOf(city) === index)

  if (uniqueCities?.length === 0) {
    return 'Aucune ville spécifiée'
  }

  if (uniqueCities && uniqueCities.length <= MAX_DISPLAY_CITIES) {
    return uniqueCities?.join(', ')
  }

  const displayedCities = uniqueCities?.slice(0, MAX_DISPLAY_CITIES).join(', ')
  // const remainingCitiesCount = cities.length - MAX_DISPLAY_CITIES
  return `${displayedCities}`
}

const formatAddress = (address?: Address): string => {
  if (!address) {
    return ''
  }

  const { addressLine1, addressLine2, postalCode, city } = address

  return `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}, ${postalCode} ${city}`
}

const getViewTypeLabel = (role: UserEnums.RoleEnums, group?: Group) => {
  if (role === UserEnums.RoleEnums.CARRIER) {
    return group && group.type === GroupEnums.GroupTypeEnum.SUPER_PRODUCER
      ? `Super-Producteur`
      : `Producteur-Livreur`
  }
  if (role === UserEnums.RoleEnums.PRODUCER) {
    return `Producteur`
  }
  if (role === UserEnums.RoleEnums.CLIENT) {
    return `Client`
  }
  return '-'
}

const getBioProductLabel = (productLabels: ProductLabel[] | undefined) => {
  return productLabels?.find((productLabel) => productLabel.label === 'BIO')
}

function stopRankingFormatter(stop: Stop): Stop {
  return {
    ...stop,
    formattedRanking: stop.ranking ? stop.ranking.toString() : '',
  }
}

/**
 * Output ISO 8601 date string with timezone offset
 * From From https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript#answer-17415677
 */
function toIsoString(date: Date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-'

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  )
}

/**
 * Format a date to a UTC string with the given time
 */
function dateToUtcString(date: Date, time: string) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${time}Z`
}

// Check if a string is a valid ISO date, aka "2021-08-31T12:00:00.000Z"
function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  const d = new Date(str)
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str // valid date
}

function removeUTCFromDate(date: string | Date): string {
  const dateStr = date instanceof Date ? date.toISOString() : date

  if (typeof dateStr !== 'string' || !isIsoDate(dateStr)) {
    return dateStr
  }
  return dateStr.replace('T', ' ').replace('Z', '')
}

function removeUTCFromBatchDate(batch: Batch) {
  batch.start = removeUTCFromDate(batch.start)
  batch.end = removeUTCFromDate(batch.end)
  return batch
}

function pad(num: number) {
  return (num < 10 ? '0' : '') + num
}

const FormatUtils = {
  formatPrice,
  getPriceUnits,
  formatUnity,
  capitalize,
  formatPercentage,
  numberToPercentage,
  formatQuantity,
  formatDate,
  stringToFloat,
  floatToString,
  formatId,
  formatVolume,
  getLabelFromDeliveryNoteType,
  cleanEmail,
  getTourTarget,
  pluralizeAmountLabel,
  moreCategories,
  startOfWeek,
  startOfWeekDate,
  getLabelFromClientType,
  getCarrierNamingFromGroupType,
  pluralizeLabel,
  formatCities,
  formatAddress,
  getViewTypeLabel,
  formatDouble,
  formatProductLabels,
  getBioProductLabel,
  stopRankingFormatter,
  toIsoString,
  dateToUtcString,
  removeUTCFromBatchDate,
  removeUTCFromDate,
  displayCarrierFees,
  convertCarrierFeesToDecimal,
}

export default FormatUtils
