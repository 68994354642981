import React from 'react'
import { useTheme } from 'styled-components/native'
import { Linking, View } from 'react-native'
import { OrderClientTopBoxProps } from './OrderClientTopBox.model'
import { Button, CardParam, Icon, Icons, Spacer, Typography } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './OrderClientTopBox.styles'

function OrderClientTopBox({ order }: OrderClientTopBoxProps) {
  const theme = useTheme()
  const nbCrates =
    order?.cart?.cartBatchs?.reduce((acc, cb) => acc + (cb?.packingQuantity || 0), 0) || 0
  const onPressCall = (phone?: string) => {
    if (phone) {
      Linking.openURL(
        `tel:${phone.replace(' ', '').replace('.', '').replace('-', '').replace('+', '')}`,
      )
    }
  }

  const mapsUrl = 'https://www.google.com/maps/search/?api=1'
  const mapsQuery = order?.cart?.client?.deliveryAddress
    ? `${`${order?.cart?.client?.deliveryAddress.addressLine1} ${order?.cart?.client?.deliveryAddress.postalCode} ${order?.cart?.client?.deliveryAddress.city}`}`
    : `${`${order?.cart?.client?.addressLine1} ${order?.cart?.client?.postalCode} ${order?.cart?.client?.city}`}`

  const wazeUrl = 'https://waze.com/ul'
  const wazeQuery = order?.cart?.client?.deliveryAddress
    ? `${order?.cart?.client?.deliveryAddress.addressLine1} ${order?.cart?.client?.deliveryAddress.postalCode} ${order?.cart?.client?.deliveryAddress.city}`
    : `${order?.cart?.client?.addressLine1} ${order?.cart?.client?.postalCode} ${order?.cart?.client?.city}`

  const openMaps = () => {
    if (
      order?.cart?.client?.deliveryAddress ||
      (order?.cart?.client?.addressLine1 &&
        order?.cart?.client?.postalCode &&
        order?.cart?.client?.city)
    ) {
      Linking.openURL(`${mapsUrl}&query=${mapsQuery}`)
    }
  }

  const openWaze = () => {
    if (
      order?.cart?.client?.deliveryAddress ||
      (order?.cart?.client?.addressLine1 &&
        order?.cart?.client?.postalCode &&
        order?.cart?.client?.city)
    ) {
      Linking.openURL(`${wazeUrl}?q=${wazeQuery}`)
    }
  }

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))}
          value={order?.cart?.client?.label || ''}
          flex={8}
          isStart
          rightBox={
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {order?.cart?.client?.phone1 && (
                <Button
                  small
                  onPress={() => onPressCall(order?.cart?.client?.phone1)}
                  colorName="color-grey"
                  style={{ width: 30, height: 30 }}
                >
                  <Icon
                    type={Icons.MaterialCommunityIcons}
                    name="phone-classic"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['text-light-1']}
                  />
                </Button>
              )}
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.5} />
              {order?.cart?.client?.phone2 && (
                <Button
                  small
                  onPress={() => onPressCall(order?.cart?.client?.phone2)}
                  colorName="color-grey"
                  style={{ width: 30, height: 30 }}
                >
                  <Icon
                    type={Icons.Ionicons}
                    name="call"
                    size={theme.spacingUnit * 2}
                    color={theme.colors['text-light-1']}
                  />
                </Button>
              )}
            </View>
          }
        />
        <CardParam
          label="Prévu le"
          value={FormatUtils.capitalize(
            FormatUtils.formatDate(
              order?.cart?.deliveryStart || order?.cart?.tour?.start,
              'FullDate',
            ),
          )}
          flex={4}
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label="Adresse"
          value={
            <>
              <Typography.Body>
                {order?.cart?.client?.deliveryAddress?.addressLine1 ||
                  order?.cart?.client?.addressLine1}
              </Typography.Body>
              {(order?.cart?.client?.deliveryAddress?.addressLine2 ||
                order?.cart?.client?.addressLine2) && (
                <Typography.Body>
                  {order?.cart?.client?.deliveryAddress?.addressLine2 ||
                    order?.cart?.client?.addressLine2}
                </Typography.Body>
              )}
              <Typography.Body>
                {order?.cart?.client?.deliveryAddress?.postalCode ||
                  order?.cart?.client?.postalCode}{' '}
                {order?.cart?.client?.deliveryAddress?.city || order?.cart?.client?.city}
              </Typography.Body>
            </>
          }
          flex={1}
          rightBox={
            <>
              <Button small onPress={() => openMaps()} label="Maps" colorName="color-grey" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Button small onPress={() => openWaze()} label="Waze" colorName="color-grey" />
            </>
          }
          isStart
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label="Indications"
          value={
            <Typography.Body>{order?.cart?.client?.deliveryAddress?.indications}</Typography.Body>
          }
          isStart
          isEnd
        />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam
          label="Commandes"
          value={order?.cart?.cartBatchs?.length || '0'}
          flex={4}
          isStart
        />
        <CardParam
          label="Ventes"
          value={`${FormatUtils.formatPrice(
            order?.totalAmounts?.totalHT || order.totalHTBatchs,
            'ht',
          )}`}
          flex={5}
          isEnd
        />
        <CardParam label="Colis" value={nbCrates} flex={3} isEnd />
      </StyledTopBox>
    </>
  )
}

export default OrderClientTopBox
