import { Producer } from '../../../../../domain'
import { Card, Col, DynamicImage, Row, Spacer, Typography } from '../../../../../components'
import {
  GalleryImageWrapper,
  StyledCardWrapper,
  StyledCardContent,
  DynamicImageWrapper,
  TextWrapper,
  DynamicImageGalleryWrapper,
} from './ProducerInfos.styles'
import DirectusUtil from '../../../../../utilities/utils/directus'
import DeviceUtil from '../../../../../utilities/utils/device'
import { VatTypeEnums } from '../../../../../../enums/vatType'

interface ProducerInfosProps {
  producer: Producer
}

const ProducerInfos = ({ producer }: ProducerInfosProps) => {
  const isMobileScreen = DeviceUtil.isMobileApp()
  const galleryImagesIds = DirectusUtil.getProducerGalleryImageUrlFromId(producer) || []

  return (
    <StyledCardWrapper>
      <Card>
        <StyledCardContent>
          <Row>
            <Col lg={2} md={2} sm={3} xs={3} xsAlignItems="center">
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <DynamicImageWrapper>
                <DynamicImage
                  togglesModal
                  imageUri={DirectusUtil.getProducerImageUrlFromId(producer)}
                />
              </DynamicImageWrapper>
            </Col>

            <Col
              lg={galleryImagesIds.length > 0 ? 6 : 10}
              md={galleryImagesIds.length > 0 ? 6 : 10}
              sm={9}
              xs={9}
            >
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <TextWrapper>
                <Typography.CardTitle>{producer.label}</Typography.CardTitle>
                <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  {producer.location}
                </Typography.BodyExtraSmall>
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body>{producer.description}</Typography.Body>
                {producer.account?.legalInfo.vatType === VatTypeEnums.WITHOUT_VAT && (
                  <>
                    <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.BodySmall colorName="color-danger">
                      Ce producteur n'est pas assujetti à la TVA, elle n'est pas déductible sur ses
                      produits.
                    </Typography.BodySmall>
                  </>
                )}
              </TextWrapper>
            </Col>
            {galleryImagesIds.length > 0 && (
              <Col lg={4} md={4} sm={12} xs={12}>
                <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                <GalleryImageWrapper isMobile={isMobileScreen}>
                  {producer.galleryImagesIds?.map((imageUri, index) => (
                    <DynamicImageGalleryWrapper key={imageUri}>
                      <DynamicImage imageUri={galleryImagesIds[index]} togglesModal />
                    </DynamicImageGalleryWrapper>
                  ))}
                </GalleryImageWrapper>
              </Col>
            )}
          </Row>
        </StyledCardContent>
      </Card>
    </StyledCardWrapper>
  )
}

export default ProducerInfos
