import { TouchableOpacity, View } from 'react-native'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Icon, Icons, Spacer, Typography } from '../../../components'
import {
  QuantityInputWrapper,
  StyledValueBox,
  StyledValuesButtonWrapper,
} from './AddDirectCartButton.styles'
import CartClientService from '../../../services/client/cart'
import { queryClient } from '../../../utilities/queryClient'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { AddDirectCartButtonProps } from './AddDirectCartButton.model'
import FormatUtils from '../../../utilities/utils/format'
import QuantityInput from '../../../components/inputs/QuantityInput'
import { CatalogItem } from '../../../domain/catalog'

const AddDirectCartButton = ({ catalogItem, cart, small, testID }: AddDirectCartButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [localCatalogItem, setLocalCatalogItem] = useState<CatalogItem | undefined>(undefined)
  const [packingQuantityValue, setPackingQuantityValue] = useState('')
  const [totalQuantityValue, setTotalQuantityValue] = useState<number | undefined>(undefined)

  const theme = useTheme()

  const { getAccessInfos } = useAuthenticationContext()

  useEffect(() => {
    if (!cart) return
    if (!catalogItem?.id) {
      setLocalCatalogItem(undefined)
      setPackingQuantityValue('')
      setTotalQuantityValue(undefined)
      return
    }

    setLocalCatalogItem(catalogItem)
    setPackingQuantityValue(FormatUtils.floatToString(catalogItem.packingQuantity))
    setTotalQuantityValue(catalogItem.totalQuantity)
  }, [cart, catalogItem])

  const onAddCartBatch = async () => {
    if (isLoading || !catalogItem?.packing) return
    setIsLoading(true)
    const newCartbatch = {
      cartId: cart.id,
      batchId: catalogItem.batch.id,
      packingQuantity: 1,
      packing: catalogItem?.packing,
      availableQuantity: catalogItem?.availableQuantity,
      totalQuantity: catalogItem?.packing,
      unitPrice: catalogItem.unitPrice,
      unitPriceProducer: catalogItem.unitPriceProducer,
      carrierFees: catalogItem.carrierFees,
      platformFees: catalogItem.platformFees,
    }

    const updateResponse = await CartClientService.createDirectCartBatch(newCartbatch)
    if (updateResponse.catalogItem.id) {
      if (newCartbatch.batchId === updateResponse.catalogItem.batch.id) {
        setLocalCatalogItem(updateResponse.catalogItem)
        setPackingQuantityValue(FormatUtils.floatToString(newCartbatch.packingQuantity))
        setTotalQuantityValue(newCartbatch.totalQuantity)
      }
    }
    queryClient.invalidateQueries(['c_cart', cart.id])
    queryClient.invalidateQueries(['direct_catalog', cart.id])
    queryClient.invalidateQueries(['current_direct_carts', getAccessInfos().producerId])

    setIsLoading(false)
  }

  const batchIsAvailable = () => {
    if (
      catalogItem.availableQuantity &&
      catalogItem.packing &&
      catalogItem.availableQuantity >= catalogItem.packing
    ) {
      const availableQuantity =
        catalogItem.availableQuantity - (localCatalogItem?.totalQuantity || 0)

      return availableQuantity > 0
    }
    return false
  }

  const onUpdateCartBatch = async (changeQuatity: number) => {
    if (isLoading || !localCatalogItem || !catalogItem?.packing) return
    if (changeQuatity > 0 && !batchIsAvailable()) {
      setPackingQuantityValue(FormatUtils.floatToString(localCatalogItem.packingQuantity))
      setTotalQuantityValue(localCatalogItem.totalQuantity)
      return
    }
    setIsLoading(true)

    let newQuantity = (localCatalogItem.packingQuantity || 0) + changeQuatity
    newQuantity = newQuantity < 0 ? 0 : newQuantity

    const newTotalQuantity = Math.round(catalogItem.packing * newQuantity * 100) / 100

    const newCartbatch = {
      id: localCatalogItem?.id,
      cartId: cart.id,
      batchId: catalogItem.batch.id,
      availableQuantity: catalogItem?.availableQuantity,
      packingQuantity: newQuantity,
      packing: catalogItem?.packing,
      totalQuantity: newTotalQuantity,
      unitPrice: catalogItem.unitPrice,
      unitPriceProducer: catalogItem.unitPriceProducer,
      carrierFees: catalogItem.carrierFees,
      platformFees: catalogItem.platformFees,
    }

    const updateResponse = await CartClientService.updateDirectCartBatch(
      catalogItem.id,
      newCartbatch,
    )

    if (updateResponse?.catalogItem?.id) {
      const newLocalCatalogItem = updateResponse.catalogItem
      newLocalCatalogItem.packingQuantity = newQuantity
      newLocalCatalogItem.totalQuantity = newTotalQuantity
      setLocalCatalogItem(newLocalCatalogItem)
      setPackingQuantityValue(FormatUtils.floatToString(newLocalCatalogItem.packingQuantity))
      setTotalQuantityValue(newLocalCatalogItem.totalQuantity)
    }
    queryClient.invalidateQueries(['c_cart', cart.id])
    queryClient.invalidateQueries(['direct_catalog', cart.id])
    queryClient.invalidateQueries(['current_direct_carts', getAccessInfos().producerId])
    setIsLoading(false)
  }

  const onChangePackingQuantityValue = (value: string) => {
    const newPackingQuantity = FormatUtils.stringToFloat(value)

    if (catalogItem?.packing) {
      const newTotalQuantity =
        Math.round((newPackingQuantity || 0) * catalogItem.packing * 100) / 100
      setTotalQuantityValue(newTotalQuantity)
    }

    setPackingQuantityValue(value)
  }

  const onChangeQuantity = () => {
    const newPackingQuantity = FormatUtils.stringToFloat(packingQuantityValue)
    const diffQuantity = newPackingQuantity - (localCatalogItem?.packingQuantity || 0)
    onUpdateCartBatch(diffQuantity)
  }

  const vSpaceSize = 0.5

  return (
    <>
      <Spacer size={vSpaceSize * 3.5} axis={Spacer.AxisEnum.VERTICAL} />
      {localCatalogItem &&
      localCatalogItem?.totalQuantity &&
      localCatalogItem?.totalQuantity > 0 ? (
        <View style={{ alignItems: 'center', width: '100%' }}>
          <StyledValuesButtonWrapper>
            <View style={{ flex: 1 }} />
            <StyledValueBox>
              <TouchableOpacity onPress={() => onUpdateCartBatch(-1)} disabled={isLoading}>
                <Icon
                  type={Icons.Ionicons}
                  name="remove-circle"
                  size={small ? theme.spacingUnit * 3 : theme.spacingUnit * 3.5}
                  color={theme.colors['color-primary']}
                />
              </TouchableOpacity>
            </StyledValueBox>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            {small ? (
              <StyledValueBox>
                <Typography.Body semiBold colorName="color-primary">
                  {localCatalogItem?.packingQuantity} colis
                </Typography.Body>
                <Typography.Body semiBold colorName="color-primary">
                  (
                  {FormatUtils.formatQuantity(
                    localCatalogItem?.totalQuantity,
                    catalogItem.product?.mesureType,
                  )}
                  )
                </Typography.Body>
              </StyledValueBox>
            ) : (
              <StyledValueBox>
                <QuantityInputWrapper>
                  <QuantityInput
                    field="text"
                    value={packingQuantityValue}
                    suffix="colis"
                    onChangeText={onChangePackingQuantityValue}
                    onBlurCallBack={onChangeQuantity}
                    onKeyPressCallBack={onChangeQuantity}
                    small
                  />
                </QuantityInputWrapper>
              </StyledValueBox>
            )}

            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <StyledValueBox>
              <TouchableOpacity
                onPress={() => onUpdateCartBatch(1)}
                disabled={isLoading || !batchIsAvailable()}
              >
                <Icon
                  type={Icons.Ionicons}
                  name="add-circle"
                  size={small ? theme.spacingUnit * 3 : theme.spacingUnit * 3.5}
                  color={
                    !batchIsAvailable() ? theme.colors['color-grey'] : theme.colors['color-primary']
                  }
                />
              </TouchableOpacity>
            </StyledValueBox>
            <View style={{ flex: 1 }} />
          </StyledValuesButtonWrapper>
          {!small && totalQuantityValue && totalQuantityValue > 0 && (
            <>
              <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall semiBold colorName="color-primary" align="center">
                (
                {FormatUtils.formatQuantity(
                  totalQuantityValue,
                  catalogItem.product?.mesureType,
                  true,
                )}
                )
              </Typography.BodySmall>
            </>
          )}
        </View>
      ) : (
        <StyledValuesButtonWrapper>
          <View style={{ flex: 1 }} />
          <Button
            small
            lowPadding
            rounded
            label={batchIsAvailable() ? 'Ajouter' : 'Rupture'}
            onPress={() => onAddCartBatch()}
            disabled={isLoading || !batchIsAvailable()}
            testID={testID}
          />
          <View style={{ flex: 1 }} />
        </StyledValuesButtonWrapper>
      )}
    </>
  )
}

export default AddDirectCartButton
