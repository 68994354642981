import { View } from 'react-native'
import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button, Typography, Spacer, SectionTitle, TextInput } from '../../../components'
import { Producer } from '../../../domain'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import {
  StyledButtonWrapper,
  TextInputWrapper,
  ToursContentWrapper,
} from './ProducerDirectCartCatalog.styles.'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import Loader from '../../../components/Loader'
import DirectBatchCardNew from '../../../components/DirectBatchCardNew'
import CartProducerService from '../../../services/producer/cart'
import ProducerProducerService from '../../../services/producer/producer'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import DirectOrderTopBox from '../../../components/DirectOrderTopBox'
import CardListPaginated from '../../../components/CardListPaginated'
import { CatalogItem } from '../../../domain/catalog'

const ProducerDirectCartCatalogScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerDirectCartCatalog'>>()
  const cartId = route.params?.cartId
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [pageSize, setPageSize] = useState(4)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const { data: producerData } = useQuery<
    { producer: Producer; stepsData: TutorialStepData[] },
    Error
  >(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  const {
    data: catalogData,
    isLoading,
    refetch: refetchCatalog,
  } = useQuery(
    ['direct_catalog', cartId],
    () =>
      CartProducerService.getDirectCatalog({
        cartId: cartId || '',
        page: pageNumber,
        limit: pageSize,
        search: searchValue.length >= 3 ? searchValue : undefined,
      }),
    {
      enabled: !!cartId,
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      refetchCatalog()
    }
  }, [refetchCatalog, searchValue])

  if (isLoading || !catalogData?.cart) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <DirectOrderTopBox cart={catalogData.cart} />
      <ContentWrapper marginTopLarge>
        <ToursContentWrapper>
          <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
          <SectionTitle title="Ajoutez des produits à la commande de ce client" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <TextInputWrapper>
            <TextInput
              field="text"
              label="Rechercher par nom"
              value={searchValue}
              onChangeText={setSearchValue}
              small
            />
          </TextInputWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {catalogData.catalogItems.length < 1 && (
            <View style={{ alignItems: 'center', width: '100%' }}>
              <Spacer fixedSize={2} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall align="center">
                Vous n&apos;avez aucun lot de produit disponible dans votre mercuriale
              </Typography.BodySmall>
            </View>
          )}
          <CardListPaginated
            data={catalogData.catalogItems}
            pagination={catalogData.pagination}
            element={(item: CatalogItem) => (
              <DirectBatchCardNew
                key={item.batch.id}
                catalogItem={item}
                cart={catalogData.cart}
                producer={producerData?.producer}
              />
            )}
          />

          <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
          <StyledButtonWrapper>
            <Button
              label="Confirmer l'ajout"
              onPress={() => {
                navigation.navigate('ProducerDirectCartUpdate', { cartId })
              }}
            />
          </StyledButtonWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerDirectCartCatalogScreen
