import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import DeviceUtil from '../../utilities/utils/device'
import BottomTabs from '../components/BottomTabs'
import CustomDrawer from '../components/Drawer'
import { NavigationItemType } from '../Navigation.model'
import { Icons } from '../../components'
import ProducerDashboardScreen from '../../screens/Producer/ProducerDashboard'
import HeaderHome from '../components/HeaderHome'
import TourProducerNavigation from './TourProducerNavigation/TourProducerNavigation'
import ProductProducerNavigation from './ProductProducerNavigation/ProductProducerNavigation'
import BatchProducerNavigation from './BatchProducerNavigation/BatchProducerNavigation'
import BillingProducerNavigation from './BillingProducerNavigation/BillingProducerNavigation'
import useAuthenticationContext from '../../utilities/hook/useAuthenticationContext'
import useCardTitleOptions from '../AuthenticationNavigationStack/AuthenticationNavigationStack.utils'
import ProfileSwitcherScreen from '../../screens/Authentification/ProfileSwitcher'
import Header from '../components/Header'
import PendingAccountScreen from '../../screens/Common/PendingAccount'
import ProducerGroupsNavigation from './ProducerGroupsNavigation/ProducerGroupsNavigation'

const navigationItems: NavigationItemType[] = [
  {
    route: 'ProducerDashboard',
    label: 'Accueil',
    type: Icons.Ionicons,
    icon: 'home',
    component: ProducerDashboardScreen,
    screenOptions: {
      headerShown: true,
      header: () => <HeaderHome />,
    },
  },
  {
    route: 'ProducerCatalog',
    label: 'Catalogue',
    type: Icons.MaterialCommunityIcons,
    icon: 'view-list',
    component: ProductProducerNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
  {
    route: 'ProducerTours',
    label: 'Tournées',
    type: Icons.MaterialCommunityIcons,
    icon: 'truck',
    component: TourProducerNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
  {
    route: 'PropositionProducerNavigationConfiguration',
    label: 'Mercuriales',
    type: Icons.Ionicons,
    icon: 'pricetags',
    component: BatchProducerNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
  {
    route: 'ProducerGroups',
    label: 'Groupe',
    type: Icons.Ionicons,
    icon: 'people',
    component: ProducerGroupsNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
  {
    route: 'ProducerBilling',
    label: 'Gestion',
    type: Icons.Ionicons,
    icon: 'wallet',
    component: BillingProducerNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
]

const Stack = createStackNavigator()

const ProducerMainNavigationStack = () => {
  const { getAccessInfos } = useAuthenticationContext()

  if (getAccessInfos().producerStatus && getAccessInfos().producerStatus === 2) {
    const headerOptions = useCardTitleOptions()

    return (
      <Stack.Navigator
        initialRouteName="PendingAccount"
        screenOptions={{
          ...headerOptions,
          keyboardHandlingEnabled: false,
        }}
      >
        <Stack.Screen
          name="PendingAccount"
          component={PendingAccountScreen}
          options={{ title: 'Validation en attente' }}
        />
        <Stack.Screen
          name="ProfileSwitcher"
          component={ProfileSwitcherScreen}
          options={{
            gestureEnabled: false,
            headerShown: true,
            animationEnabled: false,
            title: 'Choix du compte',
            header: () => <Header title="" goBackFallback="" leftButton={<></>} />,
          }}
        />
      </Stack.Navigator>
    )
  }

  if (DeviceUtil.isMobileApp()) {
    return <BottomTabs items={navigationItems} />
  } else {
    return <CustomDrawer items={navigationItems} />
  }
}

export default ProducerMainNavigationStack
