import { useQuery } from '@tanstack/react-query'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { View } from 'react-native'
import { useState } from 'react'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button, Card, PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { Client } from '../../../domain'
import ClientClientService from '../../../services/client/client'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import CopyPasteAddressCard from '../../../components/CopyPasteAddressCard'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import CopyPasteCard from '../../../components/CopyPasteCard'
import FormatUtils from '../../../utilities/utils/format'
import i18n from '../../../i18n'
import CommercialAgreementService from '../../../services/commercial-agreement'

const ProducerClientScreen = () => {
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerClient'>>()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [isCopied, setIsCopied] = useState(false)
  const { clientId, commercialAgreementId } = route.params ?? {
    clientId: '',
    commercialAgreementId: '',
  }

  const {
    data: client,
    refetch: refetchClient,
    isInitialLoading: isClientLoading,
  } = useQuery<Client, Error>(
    ['p_client', clientId],
    () => ClientClientService.getOne(clientId, true),
    {
      keepPreviousData: true,
    },
  )

  const { query, refetch } =
    CommercialAgreementService.useGetCommercialAgreement(commercialAgreementId)

  const { data: commercialAgreement } = query()

  const handleCopyClientNumber = () => {
    const clientNumberInfoCsvCopy = `${client?.phone1}`
    navigator.clipboard.writeText(clientNumberInfoCsvCopy)

    setIsCopied(true)
  }
  const handleCopyClientMail = () => {
    const clientMailInfoCsvCopy = `${client?.mail}`
    navigator.clipboard.writeText(clientMailInfoCsvCopy)

    setIsCopied(true)
  }

  const handleCopyClientDeliveryAddress = () => {
    const clientDeliveryAddressInfoCsvCopy = `${client?.addressLine1}\n${client?.addressLine2}\n${client?.postalCode} ${client?.city}`
    navigator.clipboard.writeText(clientDeliveryAddressInfoCsvCopy)

    setIsCopied(true)
  }

  const handleCopyClientBillingAddress = () => {
    const clientBillingAddressInfoCsvCopy = `${client?.account?.address.addressLine1}\n${client?.account?.address.addressLine2}\n${client?.account?.address.postalCode} ${client?.account?.address.city}`
    navigator.clipboard.writeText(clientBillingAddressInfoCsvCopy)

    setIsCopied(true)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <View style={{ width: '100%' }}>
          <PageTitle
            title="Client"
            buttonRight={
              commercialAgreementId ? (
                <Button
                  small
                  label={FormatUtils.capitalize(i18n.t('update'))}
                  onPress={() =>
                    navigation.navigate('ProducerUpdateClient', { clientId, commercialAgreementId })
                  }
                />
              ) : null
            }
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Card isLowMargin>
            <Typography.CardTitle bold style={{ width: '100%' }}>
              Nom de l&apos;enseigne
            </Typography.CardTitle>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.Body>{client?.label}</Typography.Body>
          </Card>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CopyPasteCard
            data={{ label: 'N° de téléphone', value: client?.phone1 ?? '' }}
            onPress={handleCopyClientNumber}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <CopyPasteCard
            data={{ label: 'Email', value: client?.mail ?? '' }}
            onPress={handleCopyClientMail}
          />
          {commercialAgreementId && commercialAgreement && commercialAgreement.carrierFees ? (
            <View>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Card isLowMargin>
                <Typography.CardTitle bold style={{ width: '100%' }}>
                  Commission logistique (en %)
                </Typography.CardTitle>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                <Typography.Body>
                  {FormatUtils.formatPercentage(Number(commercialAgreement.carrierFees))}
                </Typography.Body>
              </Card>
            </View>
          ) : null}
        </View>
        <SectionTitle title="Adresse de livraison" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {client?.deliveryAddress ? (
          <CopyPasteAddressCard
            clientAddress={client?.deliveryAddress}
            onPress={handleCopyClientDeliveryAddress}
          />
        ) : (
          <View style={{ width: '100%' }}>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.BodySmall colorName="text-dark-3" align="center">
              Aucune adresse de livraison renseignée
            </Typography.BodySmall>
          </View>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <SectionTitle title="Adresse de facturation" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {client?.account?.address ? (
          <CopyPasteAddressCard
            clientAddress={client?.account?.address}
            onPress={handleCopyClientBillingAddress}
          />
        ) : (
          <View style={{ width: '100%' }}>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.BodySmall colorName="text-dark-3" align="center">
              Aucune adresse de facturation renseignée
            </Typography.BodySmall>
          </View>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerClientScreen
