import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Spacer } from '../../../../components'
import CardListPaginated from '../../../../components/CardListPaginated'
import FilterModule from '../../../../components/FilterModule'
import PageTitle from '../../../../components/PageTitle'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import { InvoiceStatement } from '../../../../domain/InvoiceStatement'
import InvoiceStatementService from '../../../../services/invoice-statement'
import {
  Filter,
  InvoiceFilterEnum,
  SelectedValues,
} from '../../../../components/FilterModule/FilterModule.model'
import { INVOICE_STATEMENT_STATUSES, InvoiceStatusEnum } from '../../../../../enums/invoice'
import PdfUtils from '../../../../utilities/utils/pdf'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import { InvoiceStatementTypeEnum } from '../../../../../enums/invoice-statement'
import AccountingDocumentCard from '../../../../components/AccountingDocumentCard'
import useFilterParams from '../../../../utilities/hook/useFilterParams'
import { usePagination } from '../../../../utilities/hook/usePagination'
import DateFilter from '../../../../components/DateFilter'

const InvoiceStatementListScreen = () => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<SelectedValues>({})
  const [currentlyUpdatingFilter, setCurrentlyUpdatingFilter] = useState<InvoiceFilterEnum | null>(
    null,
  )
  const { filters, setPage, setDates, getDates, setPageSize, getDefaultDates } = useFilterParams()

  const { start, end } = getDates()
  const metricsFilters = { start, end }
  const { getAccessInfos } = useAuthenticationContext()
  const isClient = getAccessInfos().clientId !== null

  const { query: invoiceStatementsQuery } = InvoiceStatementService.useFindInvoiceStatements({
    status: selectedFilters[InvoiceFilterEnum.STATUS] as InvoiceStatusEnum | undefined,
    type: isClient ? InvoiceStatementTypeEnum.CLIENT : InvoiceStatementTypeEnum.PRODUCER,
    start,
    end,
    limit: filters.pageSize,
    pageNumber: filters.page,
  })

  const {
    data: invoiceStatementData,
    refetch: refetchInvoiceStatements,
    isLoading: invoiceStatementsLoading,
  } = invoiceStatementsQuery()

  // Set the initial page size only once when component mounts
  useEffect(() => {
    setPageSize(5)
    refetchInvoiceStatements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty dependency array means this runs once on mount

  const invoiceStatementList = invoiceStatementData?.data || []

  const pagination = usePagination({
    totalItems: invoiceStatementData?.pagination?.totalCount,
  })

  const getStatusFilter = useCallback((): Filter<InvoiceFilterEnum>[] => {
    const getConditionalLabel = (filterKey: InvoiceFilterEnum) => {
      if (selectedFilters[filterKey] && filterKey === InvoiceFilterEnum.STATUS) {
        const selectedStatus = INVOICE_STATEMENT_STATUSES.find(
          (status) => status.value.toString() === selectedFilters[filterKey]?.toString(),
        )
        return `Statut : ${selectedStatus?.label || 'Sélectionné'}`
      }
      return 'par statut'
    }

    return [
      {
        key: InvoiceFilterEnum.STATUS,
        active: !!selectedFilters[InvoiceFilterEnum.STATUS],
        label: getConditionalLabel(InvoiceFilterEnum.STATUS),
        options: INVOICE_STATEMENT_STATUSES.map((status) => ({
          label: status.label,
          value: status.value,
        })),
      },
    ]
  }, [selectedFilters])

  const handleFilterUpdate = async (filterKey: InvoiceFilterEnum, selectedValue?: any) => {
    if (selectedValue === undefined) {
      setCurrentlyUpdatingFilter(currentlyUpdatingFilter === filterKey ? null : filterKey)
    } else {
      setCurrentlyUpdatingFilter(null)
      setSelectedFilters((prev) => ({
        ...prev,
        [filterKey]: selectedValue,
      }))
      setPage(1)
      await refetchInvoiceStatements()
    }
  }

  const handleFilterDelete = async (filterKey: InvoiceFilterEnum) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev }
      delete newFilters[filterKey]
      return newFilters
    })
    setCurrentlyUpdatingFilter(null)
    setPage(1)
    await refetchInvoiceStatements()
  }

  const onClickDownloadInvoiceStatement = async (invoiceStatement: InvoiceStatement) => {
    setIsDownloading(true)
    try {
      const base64Response = await InvoiceStatementService.getPdf(invoiceStatement.id)
      if (!PdfUtils.isValidBase64Pdf(base64Response)) {
        throw new Error("Le PDF reçu n'est pas valide")
      }
      PdfUtils.downloadPdf(base64Response, invoiceStatement.fileName)
    } finally {
      setIsDownloading(false)
    }
  }

  const onClickMetricsChangeDate = async (value?: number, exactDate?: any, paramDate?: string) => {
    setDates(value, exactDate, paramDate)
    await refetchInvoiceStatements()
  }

  const formatFilterLabel = (key: InvoiceFilterEnum) => {
    switch (key) {
      case InvoiceFilterEnum.STATUS:
        return 'statut'
      default:
        return `option`
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Relevés" />
        <DateFilter
          onClickMetricsChangeDate={onClickMetricsChangeDate}
          metricsFilters={metricsFilters}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <FilterModule<InvoiceFilterEnum>
          filters={getStatusFilter()}
          currentlyUpdating={currentlyUpdatingFilter}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
          formatFilterLabel={formatFilterLabel}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <CardListPaginated
          data={invoiceStatementList}
          pagination={{
            page: pagination.page,
            limit: pagination.pageSize,
            totalCount: pagination.totalCount,
            totalPages: pagination.totalPages,
          }}
          element={(invoiceStatement: InvoiceStatement) => (
            <AccountingDocumentCard
              key={invoiceStatement.id}
              item={invoiceStatement}
              onDownload={onClickDownloadInvoiceStatement}
              isDownloading={isDownloading}
              type="invoice-statement"
            />
          )}
          emptyMessage="Aucun relevé n'a été créé"
          isLoading={invoiceStatementsLoading || isDownloading}
          onChangePage={setPage}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default InvoiceStatementListScreen
