import { useCallback } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'

export interface PaginationState {
  page: number
  pageSize: number
  totalCount: number
  totalPages: number
}

export interface UsePaginationProps {
  defaultPage?: number
  defaultPageSize?: number
  totalItems?: number
}

export function usePagination({
  defaultPage = 1,
  defaultPageSize = 10,
  totalItems,
}: UsePaginationProps = {}) {
  const navigation = useNavigation()
  const route = useRoute()

  const currentPageSize = Number(route.params?.pageSize || defaultPageSize)

  // Handle totalItems null/undefined case here
  const safeTotalCount = totalItems ?? 0
  const safeTotalPages = safeTotalCount ? Math.ceil(safeTotalCount / currentPageSize) : 0

  const currentState: PaginationState = {
    page: Number(route.params?.page || defaultPage),
    pageSize: currentPageSize,
    totalCount: safeTotalCount,
    totalPages: safeTotalPages,
  }

  const setPage = useCallback(
    (newPage: number) => {
      navigation.setParams({
        ...route.params,
        page: newPage,
      })
    },
    [navigation, route.params],
  )

  const setPageSize = useCallback(
    (newPageSize: number) => {
      navigation.setParams({
        ...route.params,
        pageSize: newPageSize,
        page: 1,
      })
    },
    [navigation, route.params],
  )

  const goToPage = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 1 && (!currentState.totalPages || pageNumber <= currentState.totalPages)) {
        setPage(pageNumber)
      }
    },
    [currentState.totalPages, setPage],
  )

  const nextPage = useCallback(() => {
    goToPage(currentState.page + 1)
  }, [currentState.page, goToPage])

  const previousPage = useCallback(() => {
    goToPage(currentState.page - 1)
  }, [currentState.page, goToPage])

  return {
    ...currentState,
    setPage,
    setPageSize,
    goToPage,
    nextPage,
    previousPage,
    canGoNext: currentState.totalPages ? currentState.page < currentState.totalPages : true,
    canGoPrevious: currentState.page > 1,
  }
}
