import { useQuery } from '@tanstack/react-query'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import {
  CommercialAgreementType,
  ProducerClientsStatus,
} from '../../../components/FilterModule/FilterModule.model'
import { Client, ClientGroup, Pagination, Producer } from '../../../domain'
import ApiService from '../../api.service'
import { queryClient } from '../../../utilities/queryClient'

export interface ClientWithCommercialAgreement {
  id: string
  label: string
  clientType: number
  commercialAgreement: {
    id: string
    carrierFees: number
  } | null
  orderCount: number
  clientLinkTypeLabel: string
}

interface GetMixedClientParams {
  producerId: string
  hasOrders: boolean
  type: CommercialAgreementType
  status: ProducerClientsStatus
  search?: string
  limit?: number
  page?: number
}

export const getOneProducer = async (
  idProducer: string,
): Promise<{ producer: Producer; stepsData: TutorialStepData[] }> => {
  const response = await ApiService.get(`/producer/${idProducer}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getAllClients = async (
  idProducer: string,
  hasOrders?: boolean,
  limit?: number,
  page?: number,
): Promise<{ data: Client[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/producer/${idProducer}/clients`, {
    hasOrders: hasOrders ? true : undefined,
    limit,
    page,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getBillingPageURL = async (idProducer: string): Promise<string> => {
  const response = await ApiService.get(`/producer/${idProducer}/billing`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (idProducer: string, producer: any): Promise<Producer> => {
  const response = await ApiService.patch(`/producer/${idProducer}`, producer).catch((error) => {
    throw error
  })
  return response
}

const getMixedClients = async ({
  producerId,
  hasOrders,
  type,
  status,
  search,
  limit,
  page,
}: GetMixedClientParams): Promise<{
  data: ClientWithCommercialAgreement[]
  pagination?: Pagination
}> => {
  const response = await ApiService.get(`commercial-agreement/${producerId}/mixed`, {
    hasOrders,
    type,
    status,
    search,
    limit,
    page,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

// ============================ custom hook ============================

export type UseGetMixedClientsArgs = GetMixedClientParams

function useGetMixedClients(args: UseGetMixedClientsArgs) {
  function getQueryKey() {
    return [
      'commercial-agreement',
      args.producerId ? `producerId_${args.producerId}` : '',
      args.hasOrders ? `hasOrders_${args.hasOrders}` : false,
      args.type ? `type_${args.type}` : '',
      args.status ? `status_${args.status}` : '',
      args.limit ? `limit_${args.limit}` : '',
      args.page ? `page_${args.page}` : '',
    ].filter(Boolean)
  }

  function query() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<{ data: ClientWithCommercialAgreement[]; pagination?: Pagination }, Error>({
      queryKey: getQueryKey(),
      queryFn: () => getMixedClients(args),
      select: (data): { data: ClientWithCommercialAgreement[]; pagination?: Pagination } => ({
        pagination: data.pagination,
        data: data.data.map((commercialAgreement) => ({
          ...commercialAgreement,
        })),
      }),
    })
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  return {
    query,
    refetch,
  }
}

const ProducerProducerService = {
  getOne: getOneProducer,
  getAllClients,
  getBillingPageURL,
  update,
  getMixedClients,
  useGetMixedClients,
}

export default ProducerProducerService
