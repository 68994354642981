import { ClientTypeEnum } from '../../../../enums'
import { DocumentEnums } from '../../../../enums/document'
import { Producer, Pagination, Client } from '../../../domain'
import ApiService from '../../api.service'

interface CreateClientParams {
  clientType?: ClientTypeEnum
  label: string
  owners?: string
  phone1?: string
  phone2?: string
  mail?: string
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
  labels?: string[]
  accountId?: string
}

const getAllProducers = async (clientId: string): Promise<Producer[]> => {
  const response = await ApiService.get(`/producer/client/${clientId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneProducer = async (producerId: string, clientId: string): Promise<Producer> => {
  const response = await ApiService.get(`/producer/${producerId}/client/${clientId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const sendDocumentRequest = async (producerId: string, clientId: string) => {
  const reponse = await ApiService.post(
    `/producer/${producerId}/client/${clientId}/document-request/${DocumentEnums.DocumentTypeEnum.ORGANIC_CERTIFICATION}`,
    {},
  )
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return reponse
}

const getByMail = async (email: string): Promise<Client> => {
  const response = await ApiService.get(`client/find`, { email }).then((result) => result)
  return response
}

const create = async (payload: CreateClientParams): Promise<Client> => {
  const response = await ApiService.post(`client`, payload).then((result) => result)
  return response
}

const ProducerClientService = {
  getAllProducers,
  getOneProducer,
  sendDocumentRequest,
  getByMail,
  create,
}

export default ProducerClientService
