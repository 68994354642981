import { useQuery, useQueryClient } from '@tanstack/react-query'
import ApiService from '../api.service'
import {
  CommercialAgreement,
  GetCommercialAgreementWithClient,
} from '../../domain/CommercialAgreement'

interface UseCommercialAgreementsArgs {
  producerId?: string
  search?: string
}

interface CreateCommercialAgreementParams {
  clientId: string
  carrierFees: number
}

const getCommercialAgreementById = async (
  commercialAgreementId?: string,
): Promise<CommercialAgreement> => {
  const response = await ApiService.get(`commercial-agreement/${commercialAgreementId}`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (
  commercialAgreementId: string,
  updateDto: { carrierFees: number },
): Promise<CommercialAgreement> => {
  const response = await ApiService.patch(
    `commercial-agreement/${commercialAgreementId}`,
    updateDto,
  )
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getByProducer = async (
  producerId: string,
  search?: string,
): Promise<CommercialAgreement[]> => {
  const response = await ApiService.get(`commercial-agreement/producer/${producerId}`, {
    search,
  }).then((result) => result)

  return response
}

const create = async (body: CreateCommercialAgreementParams): Promise<CommercialAgreement> => {
  const response = await ApiService.post(`commercial-agreement`, body).then((result) => result)

  return response
}

async function find(
  args: UseCommercialAgreementsArgs,
): Promise<GetCommercialAgreementWithClient[]> {
  const searchParams = new URLSearchParams()

  if (args.search) {
    searchParams.append('search', args.search)
  }

  const queryString = searchParams.toString()
  const url = `/commercial-agreement/producer/${args.producerId}${
    queryString ? `?${queryString}` : ''
  }`

  const response = await ApiService.get(url, {}).then((result) => result)

  return response
}

// ============================ custom hook ============================

function useCommercialAgreements(args: UseCommercialAgreementsArgs) {
  const queryClient = useQueryClient()

  function getQueryKey() {
    return [
      'commercial-agreements',
      args.producerId ? `producer_${args.producerId}` : '',
      args.search ? `search_${args.search}` : '',
    ].filter(Boolean)
  }

  function query() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<GetCommercialAgreementWithClient[], Error>({
      queryKey: getQueryKey(),
      queryFn: () => find(args),
      enabled: !!args.producerId,
      keepPreviousData: true,
    })
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  return {
    query,
    refetch,
  }
}

function useGetCommercialAgreement(commercialAgreementId?: string) {
  const queryClient = useQueryClient()

  function getQueryKey() {
    return [
      'commercial-agreement',
      commercialAgreementId ? `commercialAgreementId_${commercialAgreementId}` : '',
    ].filter(Boolean)
  }

  function query() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<CommercialAgreement, Error>({
      queryKey: getQueryKey(),
      queryFn: () => getCommercialAgreementById(commercialAgreementId),
      select: (commercialAgreement): CommercialAgreement => ({
        ...commercialAgreement,
      }),
      enabled: !!commercialAgreementId,
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
    refetch,
  }
}

const CommercialAgreementService = {
  useCommercialAgreements,
  useGetCommercialAgreement,
  update,
  getByProducer,
  create,
}

export default CommercialAgreementService
