import { Switch } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Card,
  Col,
  PageTitle,
  Row,
  Spacer,
  TitleWithTag,
  Typography,
} from '../../../components'
import {
  CartBatch,
  ClientGroup,
  DeliveryNote,
  DeliveryNotesCartBatchs,
  Pagination,
  ProducerGroup,
} from '../../../domain'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import {
  CartBatchCardContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
  ToursContentWrapper,
} from './CarrierBillingDeliveryNotes.styles'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import Loader from '../../../components/Loader'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import DateFilter from '../../../components/DateFilter'
import GroupCarrierService from '../../../services/carrier/group'
import DeliveryNoteProducerService from '../../../services/producer/deliveryNote'
import DirectusUtil from '../../../utilities/utils/directus'
import CardListPaginated from '../../../components/CardListPaginated'
import CarrierBillingDeliveryNotesMetrics from './components/CarrierBillingDeliveryNotesMetrics'
import OrderCarrierService from '../../../services/carrier/order'
import FormatUtils from '../../../utilities/utils/format'
import GeneralUtils from '../../../utilities/utils/general'
import { queryClient } from '../../../utilities/queryClient'
import AccountUtil from '../../../utilities/utils/account'
import { VatTypeEnums } from '../../../../enums/vatType'
import useFilterParams from '../../../utilities/hook/useFilterParams'
import { usePagination } from '../../../utilities/hook/usePagination'
import useFilterBar from '../../../utilities/hook/useFilterBar'
import FilterBar, { FilterButton } from './components/FilterBar'
import DeliveryNoteUtils from '../../../utilities/utils/deliveryNote'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

const CarrierBillingDeliveryNotesScreen = () => {
  const { filters, setPage, setMode, setDates, getDates } = useFilterParams()

  const {
    displayFilters,
    handleToggleFilters,
    handleChangeProducer,
    handleChangeClient,
    handleResetFilters,
    handleApplyFilters,
  } = useFilterBar()

  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()

  const { start, end } = getDates()
  const metricsFilters = { start, end }

  const { data: clientGroups } = useQuery<{ data: ClientGroup[]; pagination: Pagination }, Error>(
    ['sf_group_clients', 500, 1],
    () =>
      GroupCarrierService.getOneGroupClients(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const { data: producerGroups } = useQuery<
    { data: ProducerGroup[]; pagination: Pagination },
    Error
  >(
    ['sf_group_producers', 500, 1],
    () =>
      GroupCarrierService.getOneGroupProducers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const {
    data: deliveryNotes,
    isInitialLoading: deliveryNotesLoading,
    isLoading: isLoadingDeliveryNotes,
  } = useQuery<{ data: DeliveryNote[]; pagination: Pagination; stats: any }, Error>(
    ['sf_billing_delivery_notes', getAccessInfos().carrierGroupId, filters],
    () =>
      DeliveryNoteCarrierService.getAll(
        getAccessInfos().carrierGroupId,
        undefined,
        undefined,
        filters.client,
        filters.producer,
        new Date(filters.end || defaultDateHour2),
        new Date(filters.start || defaultDateHour),
        filters.pageSize,
        filters.page,
      ),
    {
      keepPreviousData: true,
    },
  )

  const { data: deliveryNotesStats } = useQuery<
    { data: DeliveryNote[]; pagination: Pagination; stats: any },
    Error
  >(
    ['sf_billing_delivery_notes_stats', getAccessInfos().carrierGroupId, filters],
    () =>
      OrderCarrierService.getDeliveryNoteStats(
        undefined,
        undefined,
        filters.client,
        filters.producer,
        new Date(filters.end || defaultDateHour2),
        new Date(filters.start || defaultDateHour),
      ),
    {
      keepPreviousData: true,
    },
  )

  const pagination = usePagination({
    totalItems: deliveryNotes?.pagination?.totalCount,
  })
  const selectedClient = clientGroups?.data.find(
    (clientGroup: ClientGroup) => clientGroup?.client?.id === filters.client,
  )?.client
  const selectedProducer = producerGroups?.data.find(
    (producerGroup: ProducerGroup) => producerGroup?.producer?.id === filters.producer,
  )?.producer

  const { data: deliveryNotesCartBatchs } = useQuery<
    { data: DeliveryNotesCartBatchs[]; pagination: Pagination; stats: any },
    Error
  >(
    ['sf_billing_cart_batchs', getAccessInfos().carrierGroupId],
    () =>
      OrderCarrierService.getAllCartBatchs(
        getAccessInfos().carrierGroupId,
        undefined,
        filters.client ? filters.client : undefined,
        filters.producer ? filters.producer : undefined,
        metricsFilters.end,
        metricsFilters.start,
        filters.pageSize,
        filters.page,
      ),
    {
      enabled:
        (selectedProducer && selectedProducer.id !== undefined) ||
        (filters.client && filters.client !== undefined),
    },
  )

  const { data: statsProducerSales } = useQuery<
    {
      totalHTProducer: number
      totalHT: number
      totalHTCheck: number
    },
    Error
  >(
    ['sf_billing_cart_batchs_stats', getAccessInfos().carrierGroupId, filters],
    () =>
      OrderCarrierService.getStatsProducerSales(
        getAccessInfos().carrierGroupId,
        undefined,
        filters.client ? filters.client : undefined,
        filters.producer ? filters.producer : undefined,
        metricsFilters.end,
        metricsFilters.start,
      ),
    {
      keepPreviousData: true,
      enabled:
        (selectedProducer && selectedProducer.id !== undefined) ||
        (filters.client && filters.client !== undefined),
    },
  )

  const onClickDeliveryNote = async (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return

    const deliveryNoteWithAccess = await DeliveryNoteProducerService.getOne(deliveryNote.id)
    if (!deliveryNoteWithAccess.documentId || !deliveryNoteWithAccess.accessToken) return

    window.open(
      DirectusUtil.getDocumentDownloadUrlFromId(
        deliveryNoteWithAccess.documentId,
        deliveryNoteWithAccess.accessToken,
      ),
      '_blank',
    )
  }

  const cartBatchsCsvCardTitle =
    'Produit\tTournées\tcommandes\tQuantité acheté\tPrix producteur unitaire\ttotal\n'
  let cartBatchsCsvContent = ''

  deliveryNotesCartBatchs?.data.forEach((cb: any) => {
    cartBatchsCsvContent += `${cb.productLabel}\t${cb.nbTours}\t${
      cb.nbCarts
    }\t${FormatUtils.formatQuantity(cb.totalQuantity, cb.mesureType)}\t${FormatUtils.formatPrice(
      cb.cartBatchUnitPriceProducer || cb.cartBatchUnitPrice,
    )}\t${FormatUtils.formatPrice(cb.totalCostProducer)}\n`
  })

  const copyCartBatchs = () => {
    navigator.clipboard.writeText(cartBatchsCsvCardTitle + cartBatchsCsvContent)
  }

  // Code Produit Colis Quantité Prix HT Total HT TVA
  const cartBatchsClientCsvCardTitle = 'Code\tProduit\tColis\tQuantité\tPrix HT\tTotal HT\t TVA\n'
  let cartBatchsClientCsvContent = ''

  deliveryNotes?.data.forEach((dn: DeliveryNote) => {
    cartBatchsClientCsvContent += `BL n°${dn.identifier}\t Livraison du ${FormatUtils.formatDate(
      dn.tour?.start,
      'Date',
    )}\t\t${FormatUtils.formatPrice(dn.totalAmounts.totalHT)}\n`
    dn?.order?.cart?.cartBatchs?.forEach((cb: CartBatch) => {
      cartBatchsClientCsvContent += `${cb.batch?.id.slice(-4)}\t${cb.batch?.product?.label}\t${
        cb.packingQuantity
      }\t${FormatUtils.formatQuantity(
        cb.totalQuantity,
        cb.batch?.product?.mesureType,
      )}\t${FormatUtils.formatPrice(cb.unitPrice)}\t${FormatUtils.formatPrice(
        (cb.unitPrice || 0) * (cb.totalQuantity || 0),
      )}\t ${
        cb.batch?.product?.producer?.account &&
        !AccountUtil.isProfileSubjectToVAT(cb.batch?.product?.producer?.account)
          ? '0'
          : cb.batch?.product?.vatRate
      }\n`
    })
  })

  const copyCartBatchsClient = () => {
    navigator.clipboard.writeText(cartBatchsClientCsvCardTitle + cartBatchsClientCsvContent)
  }

  const onClickMetricsChangeDate = async (value?: number, exactDate?: any, paramDate?: string) => {
    setDates(value, exactDate, paramDate)
  }

  const isDateIntervalValidForBatchs = () => {
    if (
      (selectedProducer && selectedProducer.id !== undefined) ||
      (filters.client && filters.client !== undefined)
    ) {
      if (GeneralUtils.diffDays(metricsFilters.start, metricsFilters.end) <= 46) {
        return true
      }
    }
    queryClient.setQueryData(['sf_billing_cart_batchs', getAccessInfos().carrierGroupId], undefined)
    return false
  }

  if (deliveryNotesLoading) {
    return <Loader isLoading pageLoading />
  }

  const onSwitchMode = async () => {
    if (
      filters.mode === 1 &&
      GeneralUtils.diffDays(metricsFilters.start, metricsFilters.end) > 45
    ) {
      setDates(undefined, start, 'startDate')
      setDates(undefined, end, 'endDate')
    }
    setMode(filters.mode === 1 ? 2 : 1)
  }

  const onClickExportCSV = async () => {
    const data = await OrderCarrierService.getCSVCartBatchs(
      getAccessInfos().carrierGroupId,
      undefined,
      filters.client ? filters.client : undefined,
      selectedProducer ? selectedProducer.id : undefined,
      metricsFilters.end,
      metricsFilters.start,
      filters.pageSize,
      filters.page,
    )
    const fileName = `ventes-harvy-${FormatUtils.formatDate(
      metricsFilters.start.toString(),
      'Date',
    )} au ${FormatUtils.formatDate(metricsFilters.end.toString(), 'Date')}.csv`
    DeliveryNoteUtils.downloadCSV(data, fileName)
  }

  const displayCartBatchs = () => {
    if (selectedProducer && selectedProducer.id !== undefined) {
      return (
        <>
          <StyledButtonWrapper>
            <Button small colorName="color-grey" label="Copier" onPress={() => copyCartBatchs()} />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body colorName="text-dark-1">
            Récapitulatif des ventes du producteur
          </Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Card>
            <CartBatchCardContentWrapper>
              <Row>
                <Col xs={5}>
                  <Typography.BodySmall colorName="text-dark-1">Produit</Typography.BodySmall>
                </Col>
                <Col xs={2} alignItems="center">
                  <Typography.BodySmall colorName="text-dark-3" align="center">
                    Tournées-commandes
                  </Typography.BodySmall>
                </Col>
                <Col xs={2} alignItems="center">
                  <Typography.BodySmall colorName="text-dark-3" align="center">
                    Quantité acheté
                  </Typography.BodySmall>
                </Col>
                <Col xs={3} alignItems="flex-end" justifyContent="flex-end">
                  <Typography.BodySmall colorName="text-dark-3" align="right">
                    Prix producteur unitaire - total
                  </Typography.BodySmall>
                </Col>
              </Row>
            </CartBatchCardContentWrapper>
          </Card>

          {deliveryNotesCartBatchs?.data.map((cb: any) => {
            return (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                <Card>
                  <CartBatchCardContentWrapper>
                    <Row>
                      <Col xs={5}>
                        <TitleWithTag tag={cb.internalReference}>
                          <Typography.BodySmall colorName="text-dark-1">
                            {cb.productLabel}
                          </Typography.BodySmall>
                        </TitleWithTag>
                      </Col>
                      <Col xs={2} alignItems="center">
                        <Typography.BodySmall colorName="text-dark-3">
                          {cb.nbTours}-{cb.nbCarts}
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={2} alignItems="center">
                        <Typography.BodySmall colorName="text-dark-3">
                          {FormatUtils.formatQuantity(cb.totalQuantity, cb.mesureType)}
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={3} alignItems="flex-end">
                        <Typography.BodySmall colorName="text-dark-3">
                          {FormatUtils.formatPrice(
                            cb.cartBatchUnitPriceProducer || cb.cartBatchUnitPrice,
                          )}{' '}
                          - {FormatUtils.formatPrice(cb.totalCostProducer)}
                        </Typography.BodySmall>
                      </Col>
                    </Row>
                  </CartBatchCardContentWrapper>
                </Card>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
              </>
            )
          })}
        </>
      )
    }

    return (
      <>
        <StyledButtonWrapper>
          <Button
            small
            colorName="color-grey"
            label="Copier"
            onPress={() => copyCartBatchsClient()}
          />
        </StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
        <Typography.Body colorName="text-dark-1">
          Récapitulatif des achats du client
        </Typography.Body>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Card>
          <CartBatchCardContentWrapper>
            <Row>
              <Col xs={5}>
                <Typography.BodySmall colorName="text-dark-1">Produit</Typography.BodySmall>
              </Col>
              <Col xs={2} alignItems="center">
                <Typography.BodySmall colorName="text-dark-3" align="center">
                  Colis (Quantité)
                </Typography.BodySmall>
              </Col>
              <Col xs={4} alignItems="center">
                <Typography.BodySmall colorName="text-dark-3" align="center">
                  Prix HT (Total HT)
                </Typography.BodySmall>
              </Col>
              <Col xs={1} alignItems="flex-end" justifyContent="flex-end">
                <Typography.BodySmall colorName="text-dark-3" align="right">
                  TVA
                </Typography.BodySmall>
              </Col>
            </Row>
          </CartBatchCardContentWrapper>
        </Card>

        {deliveryNotes?.data.map((dn: DeliveryNote) => {
          return (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
              <Card>
                <CartBatchCardContentWrapper>
                  <Row>
                    <Col xs={3}>
                      <Typography.BodySmall bold colorName="text-dark-1">
                        BL n°{dn.identifier}
                      </Typography.BodySmall>
                    </Col>
                    <Col xs={5} alignItems="center">
                      <Typography.BodySmall bold colorName="text-dark-1">
                        Livraison du {FormatUtils.formatDate(dn.tour?.start, 'Date')}
                      </Typography.BodySmall>
                    </Col>
                    <Col xs={4} alignItems="center">
                      <Typography.BodySmall bold colorName="text-dark-1">
                        {FormatUtils.formatPrice(dn.totalAmounts.totalHT)}HT
                      </Typography.BodySmall>
                    </Col>
                  </Row>
                </CartBatchCardContentWrapper>
              </Card>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
              {dn.order?.cart?.cartBatchs?.map((cb) => {
                return (
                  <>
                    <Card>
                      <CartBatchCardContentWrapper>
                        <Row>
                          <Col xs={5}>
                            <Typography.BodySmall colorName="text-dark-1">
                              {cb.batch?.product?.label}
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={2} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3" align="center">
                              {cb.packingQuantity} (
                              {FormatUtils.formatQuantity(
                                cb.totalQuantity,
                                cb.batch?.product?.mesureType,
                              )}
                              )
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={4} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3" align="center">
                              {FormatUtils.formatPrice(cb.unitPrice)}HT (
                              {FormatUtils.formatPrice(
                                (cb.unitPrice || 0) * (cb.totalQuantity || 0),
                              )}{' '}
                              HT)
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={1} alignItems="flex-end" justifyContent="flex-end">
                            <Typography.BodySmall colorName="text-dark-3" align="right">
                              {cb.batch?.product?.producer?.account?.legalInfo.vatType ===
                              VatTypeEnums.WITHOUT_VAT
                                ? '0'
                                : cb.batch?.product?.vatRate}
                            </Typography.BodySmall>
                          </Col>
                        </Row>
                      </CartBatchCardContentWrapper>
                    </Card>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                  </>
                )
              })}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            </>
          )
        })}
      </>
    )
  }

  const renderCartBatchContent = () => {
    if (!isDateIntervalValidForBatchs()) {
      return (
        <Typography.Body colorName="color-danger">
          L'intervalle de date ne peut pas dépasser 45 jours
        </Typography.Body>
      )
    }
    return displayCartBatchs()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Bons de livraison (${deliveryNotes?.pagination?.totalCount || 0})`}
            buttonRight={<FilterButton onPress={handleToggleFilters} />}
          />
          <FilterBar
            clientGroups={clientGroups?.data}
            producerGroups={producerGroups?.data}
            selectedClient={selectedClient}
            selectedProducer={selectedProducer}
            displayFilters={displayFilters}
            onToggleFilters={handleToggleFilters}
            onChangeProducer={handleChangeProducer}
            onChangeClient={handleChangeClient}
            onResetFilters={handleResetFilters}
            onApplyFilters={handleApplyFilters}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <DateFilter
            onClickMetricsChangeDate={onClickMetricsChangeDate}
            metricsFilters={metricsFilters}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {deliveryNotesStats && statsProducerSales && (
            <CarrierBillingDeliveryNotesMetrics
              metrics={deliveryNotesStats}
              cartBatchsStats={statsProducerSales}
            />
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {(selectedProducer || filters.client) && (
            <StyledModeSwitchWrapper>
              <Typography.Body>Affichage par BL</Typography.Body>
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
              <Switch
                trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                onValueChange={onSwitchMode}
                value={filters.mode === 2}
              />
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
              <Typography.Body>Affichage par lots</Typography.Body>
            </StyledModeSwitchWrapper>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {filters.mode === 1 ? (
            <CardListPaginated
              data={deliveryNotes?.data}
              pagination={{
                page: pagination.page,
                limit: pagination.pageSize,
                totalCount: pagination.totalCount || 0,
                totalPages: pagination.totalPages || 0,
              }}
              element={(deliveryNote: DeliveryNote) => (
                <TourDeliveryNoteCard
                  key={deliveryNote.id}
                  deliveryNote={deliveryNote}
                  onClick={onClickDeliveryNote}
                  isCarrier
                  isBilling
                />
              )}
              isLoading={deliveryNotesLoading || isLoadingDeliveryNotes}
              emptyMessage="Aucune tournée passée"
              onChangePage={setPage}
            />
          ) : (
            renderCartBatchContent()
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
          <Button
            small
            colorName="color-grey"
            label="Export Ventes CSV"
            onPress={onClickExportCSV}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierBillingDeliveryNotesScreen
