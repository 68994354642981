import { CartCreatorTypeEnum } from '../../../enums'
import { CartBatchCarrierStatusEnum, CartBatchProducerStatusEnum } from '../../../enums/cartBatch'
import { Batch } from '../Batch'
import { Client } from '../Client'
import { Order } from '../Order'
import { Producer } from '../Producer'
import { Tour, ToursFake } from '../Tour'

export type CartBatch = {
  id: string
  unitPrice?: number
  packing?: number
  packingQuantity?: number
  totalQuantity?: number
  batch?: Batch
  identifications?: string[]
  cart?: Cart
  unitPriceProducer?: number
  carrierFees?: number
  otherBatchs?: Batch[]
  carrierStatus?: CartBatchCarrierStatusEnum | null | undefined
  producerStatus?: CartBatchProducerStatusEnum | null | undefined
}

export type UdpateCartAdmin = {
  creatorType?: CartCreatorTypeEnum
}

export type UdpateCartBatch = {
  batchId?: string
  clientId?: string
  packingQuantity?: number
  packing?: number
  totalQuantity?: number
  unitPrice?: number
  unitPriceProducer?: number
  carrierFees?: number
  otherBatchs?: Batch[]
}

export type UdpateCartBatchCarrier = {
  batchId?: string
  clientId?: string
  packingQuantity?: number
  packing?: number
  totalQuantity?: number
  unitPrice?: number
  batch?: Batch
  unitPriceProducer?: number
  carrierFees?: number
  otherBatchs?: Batch[]
}

export type AddCartBatchCarrier = {
  batchId?: string
  clientId?: string
  packingQuantity?: number
  packing?: number
  totalQuantity?: number
  unitPrice?: number
  unitPriceProducer?: number
  batch?: Batch
  carrierFees?: number
  otherBatchs?: Batch[]
}

export type Cart = {
  id: string
  tour?: Tour
  date: string
  closing: string
  total: number
  order?: Order
  cartBatchs?: CartBatch[]
  client?: Client
  createdDate?: string
  totalHTBatchs?: number
  totalVAT55?: number
  totalVAT10?: number
  totalVAT20?: number
  totalTTC?: number
  deliveryFeesHT?: number
  nbCrates?: number
  producers?: Producer[]
  targetCart?: string
  newCart?: string
  creatorType?: CartCreatorTypeEnum
  deliveryStart?: string
  deliveryEnd?: string
}

export enum CartValidationRuleName {
  MinOrder = 'MinOrder',
  OrderEndDate = 'OrderEndDate',
}

export type CartValidationFailure = {
  ruleName: CartValidationRuleName
  message: string
  data?: any
}
