import React from 'react'
import { useTheme } from 'styled-components/native'
import { View } from 'react-native'
import { Card, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { DirectCartCardProps } from './DirectCartCard.model'
import {
  StyledBottomSection,
  StyledCardContent,
  StyledCardWrapper,
  StyledDeliveryInfo,
  StyledPrice,
  StyledTopSection,
} from './DirectCartCard.styles'

const DirectCartCard = ({ cart, onClick }: DirectCartCardProps) => {
  const theme = useTheme()

  const onClickCart = () => {
    onClick(cart.id)
  }

  if (!cart) return null

  return (
    <StyledCardWrapper onPress={onClickCart} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledTopSection>
            <View style={{ flex: 3 }}>
              <Typography.CardTitle ellipsis>{cart.client.label}</Typography.CardTitle>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <StyledPrice>
                <Typography.BodySmall colorName="text-dark-3" bold>
                  {FormatUtils.formatPrice(cart.totalAmounts?.totalHT || 0)}
                </Typography.BodySmall>
              </StyledPrice>
              <Icon
                type={Icons.Ionicons}
                name="chevron-forward-outline"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            </View>
          </StyledTopSection>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <StyledBottomSection>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Icon
                type={Icons.MaterialCommunityIcons}
                name="truck-cargo-container"
                size={theme.spacingUnit * 2}
                color={theme.colors['color-primary']}
              />
              <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
              <Typography.BodySmall colorName="text-dark-3">
                {FormatUtils.formatDate(cart.deliveryStart, 'WeekDay')}{' '}
                {FormatUtils.formatDate(cart.deliveryStart, 'DateShort')}
              </Typography.BodySmall>
            </View>
          </StyledBottomSection>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default DirectCartCard
