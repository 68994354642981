import React from 'react'
import { useTheme } from 'styled-components/native'
import { View } from 'react-native'
import { Card, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { OrderCardProps } from './DirectOrderCard.model'

import {
  StyledBottomSection,
  StyledCardContent,
  StyledCardWrapper,
  StyledDeliveryInfo,
  StyledPrice,
  StyledTopSection,
} from './DirectOrderCard.styles'
import { ORDER_TYPES } from '../../../domain/Order'

const DirectOrderCard = ({ order, onClick }: OrderCardProps) => {
  const theme = useTheme()

  const OnClickOrder = () => {
    onClick(order)
  }

  if (!order) return null

  const totalPackages = order.cart?.cartBatchs?.length

  const deliveryDeposit = order.deliveryDeposit || order.cart?.tour?.deposit
  const deliveryStart = order.deliveryStart || order.cart?.tour?.start

  return (
    <StyledCardWrapper onPress={OnClickOrder} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledTopSection>
            <View style={{ flex: 3 }}>
              <Typography.CardTitle ellipsis>{order.cart?.client?.label}</Typography.CardTitle>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <StyledPrice>
                <Typography.BodySmall colorName="text-dark-3" bold>
                  {totalPackages} colis /{' '}
                  {order.totalTTC ? FormatUtils.formatPrice(order.totalTTC) : 'NA'}
                </Typography.BodySmall>
              </StyledPrice>
              <Icon
                type={Icons.Ionicons}
                name="chevron-forward-outline"
                size={theme.spacingUnit * 2}
                color={theme.colors['text-dark-3']}
              />
            </View>
          </StyledTopSection>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <StyledBottomSection>
            <View style={{ flex: 1 }}>
              <Typography.BodySmall colorName="text-dark-3" bold>
                {ORDER_TYPES.find((type) => type.value === order.type)?.label}
              </Typography.BodySmall>
            </View>

            <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
              <StyledDeliveryInfo>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="database-arrow-down"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['color-primary']}
                />
                <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                <Typography.BodySmall colorName="text-dark-3">
                  {deliveryDeposit
                    ? `${FormatUtils.formatDate(
                        deliveryDeposit.toString(),
                        'WeekDay',
                      )} ${FormatUtils.formatDate(deliveryDeposit.toString(), 'DateShort')}`
                    : 'NA'}
                </Typography.BodySmall>
              </StyledDeliveryInfo>
              <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
              <StyledDeliveryInfo>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="truck-cargo-container"
                  size={theme.spacingUnit * 2}
                  color={theme.colors['color-primary']}
                />
                <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                <Typography.BodySmall colorName="text-dark-3">
                  {deliveryStart
                    ? `${FormatUtils.formatDate(
                        deliveryStart.toString(),
                        'WeekDay',
                      )} ${FormatUtils.formatDate(deliveryStart.toString(), 'DateShort')}`
                    : 'NA'}
                </Typography.BodySmall>
              </StyledDeliveryInfo>
            </View>
          </StyledBottomSection>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default DirectOrderCard
