import { useTheme } from 'styled-components/native'
import { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import {
  Button,
  DatePicker,
  Col,
  Row,
  PageTitle,
  Spacer,
  SectionTitle,
  Typography,
  Dropdown,
} from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { StyledButtonWrapper, ToursContentWrapper } from './ProducerDirectCartAddScreen.styles.'
import GeneralUtils from '../../../utilities/utils/general'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import CartProducerService from '../../../services/producer/cart'
import CommercialAgreementService from '../../../services/commercial-agreement'

const ProducerDirectCartAddScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const { getAccessInfos } = useAuthenticationContext()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const defaultDateHour = new Date()
  defaultDateHour.setUTCHours(-2)
  defaultDateHour.setUTCMinutes(0)
  const [newOrder, setNewOrder] = useState<any>({
    clientId: undefined,
    deliveryStart: defaultDateHour,
    deliveryDeposit: defaultDateHour,
  })

  const { query, refetch } = CommercialAgreementService.useCommercialAgreements({
    producerId: getAccessInfos().producerId,
  })

  const { data: commercialAgreements } = query()

  const [selectedClient, setSelectedClient] = useState<any | undefined>()

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newOrder)) return null

    const orderTemp = { ...newOrder }

    orderTemp[param as keyof typeof newOrder] = value
    setNewOrder(orderTemp)
  }

  const onChangeClient = (value: any) => {
    setSelectedClient(value)
  }

  const onChangeDate = (value: any) => {
    setErrorMessage('')

    const orderTemp = { ...newOrder }
    orderTemp.deliveryStart = value
    orderTemp.deliveryDeposit = value

    if (GeneralUtils.isDateBeforeCurrent(value)) {
      setErrorMessage('La date de la livraison doit être supérieure à la date actuelle.')
      return
    }

    setNewOrder(orderTemp)
  }

  const onChangeTimeStart = (value: any) => {
    setValue(value, 'deliveryStart')
  }

  const onChangeDateDeposit = (value: any) => {
    const newDate = value
    if (new Date(newDate) > new Date(newOrder.deliveryStart)) {
      setErrorMessage('La date de dépôt doit être avant la date de livraison.')
      return
    }
    setValue(value, 'deliveryDeposit')
  }

  const onAddOrder = async () => {
    setIsLoading(true)
    const order = {
      clientId: selectedClient?.value,
      deliveryStart: newOrder.deliveryStart,
      deliveryDeposit: newOrder.deliveryDeposit,
    }

    await CartProducerService.addDirectCart(order)
      .then((cart) => {
        setIsLoading(false)
        navigation.navigate('ProducerDirectCartCatalog', { cartId: cart.id })
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création de la commande')
        setIsLoading(false)
      })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter une commande" />

          {!commercialAgreements?.length ? (
            <Typography.Body style={{ textAlign: 'center' }}>
              Vous n'avez pas encore de clients en commande directe
            </Typography.Body>
          ) : (
            <>
              <Dropdown
                label="Client"
                onChange={onChangeClient}
                defaultValue={selectedClient?.value}
                optionsDefault={commercialAgreements.map((agreement) => ({
                  label: agreement.client.label,
                  value: agreement.client.id,
                }))}
                itemKey="client"
              />
              {selectedClient && (
                <>
                  <SectionTitle title="Livraison" />
                  <Row>
                    <Col xs={8}>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      <DatePicker
                        id="tourstartdate"
                        label="Date"
                        mode="date"
                        onChange={onChangeDate}
                        value={newOrder.deliveryStart}
                        withDay
                        hidden={!selectedClient}
                      />
                    </Col>
                    <Col xs={4}>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      <DatePicker
                        id="heuredebut"
                        label="Heure"
                        onChange={onChangeTimeStart}
                        value={newOrder.deliveryStart}
                        mode="time"
                        outputFormat="date"
                        hidden={!selectedClient}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              {selectedClient && newOrder.deliveryStart !== newOrder.defaultDateHour && (
                <>
                  <SectionTitle title="Limite de départ / dépot de la marchandise" />
                  <Row>
                    <Col xs={8}>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      <DatePicker
                        id="datedepot"
                        label="Date"
                        onChange={onChangeDateDeposit}
                        value={newOrder.deliveryDeposit}
                        mode="date"
                        outputFormat="date"
                        withDay
                        hidden={!selectedClient}
                      />
                    </Col>
                    <Col xs={4}>
                      <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                      <DatePicker
                        id="datedepot"
                        label="Heure"
                        onChange={onChangeDateDeposit}
                        value={newOrder.deliveryDeposit}
                        mode="time"
                        outputFormat="date"
                        hidden={!selectedClient}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <StyledButtonWrapper>
                {errorMessage ? (
                  <Typography.Body colorName="color-danger" style={{ textAlign: 'center' }}>
                    {errorMessage}
                  </Typography.Body>
                ) : null}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                {newOrder.deliveryDeposit !== newOrder.defaultDateHour ? (
                  <Button
                    label={FormatUtils.capitalize(i18n.t('add'))}
                    onPress={() => onAddOrder()}
                    loading={isLoading}
                    disabled={!selectedClient || !!errorMessage}
                  />
                ) : (
                  <Typography.BodySmall colorName="text-dark-3">
                    Veuillez compléter tous les champs
                  </Typography.BodySmall>
                )}
              </StyledButtonWrapper>
            </>
          )}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerDirectCartAddScreen
