import { Cart } from '../Cart'
import { DeliveryNote } from '../DeliveryNote'
import { TotalAmounts } from '../TotalAmounts'

export type UpdatesHistory = {
  carrierUpdates: object[]
  clientUpdates: object[]
  producerUpdates: object[]
}
export type Order = {
  id?: string
  ref?: string
  cart?: Cart
  date?: string
  total?: number
  createdDate?: string
  totalHTBatchs?: number
  totalVAT55?: number
  totalVAT10?: number
  totalVAT20?: number
  totalTTC?: number
  deliveryFeesHT?: number
  updatesHistory?: UpdatesHistory
  type?: OrderType
  deliveryStart?: string | null
  deliveryDeposit?: string | null
  totalAmounts?: TotalAmounts
  deliveryNotes?: DeliveryNote[]
}

export enum OrderType {
  TOUR = 1, // Traditionnal order, created from the tour catalog
  DIRECT = 2, // Direct order between a client and a producer
}

export const ORDER_TYPES = [
  { label: 'Tournée', value: OrderType.TOUR },
  { label: 'Livraison direct client', value: OrderType.DIRECT },
]

export const OrdersFake: Order[] = []
