import styled from 'styled-components/native'
import { View } from 'react-native'

export const StyledCardWrapper = styled.TouchableOpacity`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacingUnit}px;
`

export const StyledCardContent = styled.View`
  margin: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 1.2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 1.2}px;
  flex-direction: column;
`

export const StyledTopSection = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StyledBottomSection = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StyledDeliveryInfo = styled(View)`
  flex-direction: row;
  align-items: center;
`

export const StyledPrice = styled(View)`
  align-items: flex-end;
`
