import { Order, Pagination } from '../../../domain'
import ApiService from '../../api.service'

const getAllOrders = async (
  clientId: string,
  next?: boolean,
  before?: boolean,
  limit?: number,
  pageNumber: number = 1,
): Promise<{ data: Order[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/order/client/${clientId}`, {
    after: next ? new Date() : null,
    before: before ? new Date() : null,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneOrder = async (orderId: string): Promise<Order> => {
  const response = await ApiService.get(`/order/${orderId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (dto: any): Promise<Order> => {
  const response = await ApiService.post(`/order`, dto).catch((error) => {
    throw error
  })
  return response
}

const update = async (dto: any): Promise<Order> => {
  const response = await ApiService.patch(`/order`, dto).catch((error) => {
    throw error
  })
  return response
}

const deleteDirectOrder = async (orderId: string): Promise<Order> => {
  const response = await ApiService.remove(`/direct/order/${orderId}`).catch((error) => {
    throw error
  })
  return response
}

const OrderClientService = {
  getAllOrders,
  getOneOrder,
  create,
  update,
  deleteDirectOrder,
}

export default OrderClientService
