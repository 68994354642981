import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, PageTitle, Spacer, TextInput } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { Client } from '../../../domain'
import ProducerProducerService, {
  ClientWithCommercialAgreement,
} from '../../../services/producer/producer'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import ClientCard from '../../../modules/Client/ClientCard'
import FilterModule from '../../../components/FilterModule'
import {
  CommercialAgreementType,
  Filter,
  ProducerClientSelectedValues,
  ProducerClientsFilterEnum,
  ProducerClientsStatus,
} from '../../../components/FilterModule/FilterModule.model'
import useFilterParams from '../../../utilities/hook/useFilterParams'
import { usePagination } from '../../../utilities/hook/usePagination'
import CardListPaginated from '../../../components/CardListPaginated'
import { TextInputWrapper } from './ProducerClients.styles'
import FormatUtils from '../../../utilities/utils/format'
import i18n from '../../../i18n'

const ProducerClientsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [selectedFilters, setSelectedFilters] = useState<ProducerClientSelectedValues>({})
  const [currentlyUpdatingFilter, setCurrentlyUpdatingFilter] =
    useState<ProducerClientsFilterEnum | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const { filters, setPage, setPageSize } = useFilterParams()

  const { query: commercialAgreementQuery } = ProducerProducerService.useGetMixedClients({
    producerId: getAccessInfos().producerId,
    hasOrders: false,
    type: selectedFilters[ProducerClientsFilterEnum.TYPE] ?? CommercialAgreementType.BOTH,
    status: selectedFilters[ProducerClientsFilterEnum.STATUS] ?? ProducerClientsStatus.BOTH,
    search: searchValue,
    limit: filters.pageSize,
    page: filters.page,
  })

  const {
    data: commercialAgreementData,
    refetch: refetchCommercialAgreements,
    isLoading: commercialAgreementsLoading,
  } = commercialAgreementQuery()

  const pagination = usePagination({
    totalItems: commercialAgreementData?.pagination?.totalCount,
  })

  useEffect(() => {
    setPageSize(5)
    if (!searchValue || searchValue.length > 2) {
      refetchCommercialAgreements()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchCommercialAgreements, searchValue])

  const getClientFilters = useCallback((): Filter<ProducerClientsFilterEnum>[] => {
    const getConditionalLabel = (filterKey: ProducerClientsFilterEnum): string => {
      if (!selectedFilters || !selectedFilters[filterKey]) {
        return filterKey === ProducerClientsFilterEnum.TYPE ? 'par type' : 'par statut'
      }

      if (filterKey === ProducerClientsFilterEnum.TYPE) {
        switch (selectedFilters[filterKey]) {
          case CommercialAgreementType.DIRECT:
            return 'Type : Client direct'
          case CommercialAgreementType.GROUP:
            return "Type : Client d'un groupe"
          case CommercialAgreementType.BOTH:
            return 'Type : Tous'
          default:
            return 'Type : Tous'
        }
      }

      if (filterKey === ProducerClientsFilterEnum.STATUS) {
        switch (selectedFilters[filterKey]) {
          case ProducerClientsStatus.ACTIVE:
            return 'Statut : Actif'
          case ProducerClientsStatus.UNACTIVE:
            return 'Statut : Inactif'
          case ProducerClientsStatus.BOTH:
            return 'Statut : Tous'
          default:
            return 'Statut : Tous'
        }
      }

      return filterKey === ProducerClientsFilterEnum.TYPE ? 'par type' : 'par statut'
    }

    return [
      {
        key: ProducerClientsFilterEnum.TYPE,
        active: Boolean(selectedFilters?.[ProducerClientsFilterEnum.TYPE]),
        label: getConditionalLabel(ProducerClientsFilterEnum.TYPE),
        options: [
          { label: 'Tous', value: CommercialAgreementType.BOTH },
          { label: 'Client direct', value: CommercialAgreementType.DIRECT },
          { label: "Client d'un groupe", value: CommercialAgreementType.GROUP },
        ],
      },
      {
        key: ProducerClientsFilterEnum.STATUS,
        active: Boolean(selectedFilters?.[ProducerClientsFilterEnum.STATUS]),
        label: getConditionalLabel(ProducerClientsFilterEnum.STATUS),
        options: [
          { label: 'Tous', value: ProducerClientsStatus.BOTH },
          { label: 'Actif', value: ProducerClientsStatus.ACTIVE },
          { label: 'Inactif', value: ProducerClientsStatus.UNACTIVE },
        ],
      },
    ]
  }, [selectedFilters])

  const handleFilterUpdate = async (filterKey: ProducerClientsFilterEnum, selectedValue?: any) => {
    if (selectedValue === undefined) {
      setCurrentlyUpdatingFilter(currentlyUpdatingFilter === filterKey ? null : filterKey)
    } else {
      setCurrentlyUpdatingFilter(null)
      setSelectedFilters((prev) => ({
        ...prev,
        [filterKey]: selectedValue,
      }))
      setPage(1)
      await refetchCommercialAgreements()
    }
  }

  const handleFilterDelete = async (filterKey: ProducerClientsFilterEnum) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev }
      delete newFilters[filterKey]
      return newFilters
    })
    setCurrentlyUpdatingFilter(null)

    setPage(1)
    await refetchCommercialAgreements()
  }

  const onClickCard = (clientId: string, commercialAgreementId?: string) => {
    navigation.navigate('ProducerClient', { clientId, commercialAgreementId })
  }

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    setPage(1)
  }

  const onClickAddClient = () => {
    navigation.navigate('ProducerAddClient', {})
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle
          title="Clients"
          buttonRight={
            <Button
              small
              label={FormatUtils.capitalize(i18n.t('add'))}
              onPress={() => onClickAddClient()}
            />
          }
        />
        <FilterModule<ProducerClientsFilterEnum>
          filters={getClientFilters()}
          currentlyUpdating={currentlyUpdatingFilter}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
          formatFilterLabel={(key) => (key === ProducerClientsFilterEnum.TYPE ? 'type' : 'statut')}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={onChangeSearchValue}
            small
          />
        </TextInputWrapper>
        <CardListPaginated
          data={commercialAgreementData?.data}
          pagination={{
            page: pagination.page,
            limit: pagination.pageSize,
            totalCount: pagination.totalCount,
            totalPages: pagination.totalPages,
          }}
          element={(commercialAgreement: ClientWithCommercialAgreement) => {
            return (
              <ClientCard
                key={commercialAgreement.id}
                client={commercialAgreement as Client}
                minInfos
                lowMargin
                clientLinkTypeLabel={commercialAgreement.clientLinkTypeLabel}
                onClick={() =>
                  onClickCard(commercialAgreement.id, commercialAgreement.commercialAgreement?.id)
                }
              />
            )
          }}
          emptyMessage="Aucun client n'a été trouvé"
          isLoading={commercialAgreementsLoading}
          onChangePage={setPage}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerClientsScreen
