import { useTheme } from 'styled-components/native'
import { useQuery } from '@tanstack/react-query'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useState } from 'react'
import { Button, PageTitle, Spacer, SectionTitle } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import OrderProducerService from '../../../services/producer/order'
import CartProducerService from '../../../services/producer/cart'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import CardListPaginated from '../../../components/CardListPaginated'
import ExtendedDateFilter, { MetricsFilters } from '../../../components/DateFilterExtended'
import FilterModule from '../../../components/FilterModule'
import { Order, ORDER_TYPES } from '../../../domain/Order/order.model'
import DirectOrderCard from '../../../modules/Order/DirectOrderCard'
import DirectCartCard from '../../../modules/Cart/DirectCartCard'
import { FilterModuleProps } from '../../../components/FilterModule/FilterModule.model'
import { ToursContentWrapper } from './ProducerOrderList.styles'
import { CatalogCart } from '../../../domain/catalog'
import { Pagination } from '../../../domain/Pagination/pagination.model'

const OrderFilterEnum = {
  TYPE: 'type',
} as const

type OrderFilterType = (typeof OrderFilterEnum)[keyof typeof OrderFilterEnum]

type OrderFilter = {
  key: OrderFilterType
  active: boolean
  label: string
  options: { label: string; value: any }[]
}

const ALL_FILTER_VALUE = null

const ProducerOrderListScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [cartsPage, setCartsPage] = useState(1)
  const [cartsLimit, setCartsLimit] = useState(3)
  const [selectedFilter, setSelectedFilter] = useState<number | null>(ALL_FILTER_VALUE)
  const [currentlyUpdating, setCurrentlyUpdating] = useState<OrderFilterType | null>(null)

  const [metricsFilters, setMetricsFilters] = useState(() => {
    const currentDate = new Date()
    return {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59),
    }
  })

  const {
    data: ordersData,
    isLoading: ordersLoading,
    refetch: refetchOrders,
  } = useQuery(
    ['producer_orders', getAccessInfos().producerId, page, selectedFilter, metricsFilters],
    () =>
      OrderProducerService.getProducerOrders(getAccessInfos().producerId, {
        page,
        limit,
        type: selectedFilter || undefined,
        after: metricsFilters.start.toISOString(),
        before: metricsFilters.end.toISOString(),
      }),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  const { data: currentCarts, isLoading: cartsLoading } = useQuery<{
    data: CatalogCart[]
    pagination: Pagination
  }>(
    ['current_direct_carts', getAccessInfos().producerId, cartsPage, cartsLimit],
    () => {
      const tenDaysAgo = new Date()
      tenDaysAgo.setDate(tenDaysAgo.getDate() - 10)

      return CartProducerService.getCurrentDirectCarts({
        producerId: getAccessInfos().producerId,
        page: cartsPage,
        limit: cartsLimit,
        after: tenDaysAgo.toISOString(),
      })
    },
    {
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  const getFilters = useCallback((): OrderFilter[] => {
    const getConditionalLabel = () => {
      if (selectedFilter === ALL_FILTER_VALUE) {
        return 'Type : Tous'
      }
      const selectedType = ORDER_TYPES.find((type) => type.value === selectedFilter)
      return `Type : ${selectedType?.label}`
    }

    return [
      {
        key: OrderFilterEnum.TYPE,
        active: true,
        label: getConditionalLabel(),
        options: [
          { label: 'Tous', value: ALL_FILTER_VALUE },
          ...ORDER_TYPES.map((type) => ({
            label: type.label,
            value: type.value,
          })),
        ],
      },
    ]
  }, [selectedFilter])

  const handleFilterUpdate = (filterKey: OrderFilterType, value?: any) => {
    if (value === undefined) {
      setCurrentlyUpdating(currentlyUpdating === filterKey ? null : filterKey)
    } else {
      setCurrentlyUpdating(null)
      if (filterKey === OrderFilterEnum.TYPE) {
        setSelectedFilter(value)
        setPage(1)
      }
    }
  }

  const handleFilterDelete = (filterKey: OrderFilterType) => {
    if (filterKey === OrderFilterEnum.TYPE) {
      setSelectedFilter(ALL_FILTER_VALUE)
      setPage(1)
    }
  }

  const onPressAddOrder = () => {
    navigation.navigate('ProducerDirectCartAdd' as any)
  }

  const handleDateFilterChange = useCallback((newFilters: MetricsFilters) => {
    setMetricsFilters(newFilters)
    setPage(1)
  }, [])

  const onChangePage = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const onChangeCartsPage = (pageNumber: number) => {
    setCartsPage(pageNumber)
  }

  const onPressCart = (cartId: string) => {
    navigation.navigate('ProducerDirectCartCatalog', { cartId })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title="Commandes"
            buttonRight={<Button label="Ajouter" onPress={onPressAddOrder} />}
          />

          <ExtendedDateFilter onFilterChange={handleDateFilterChange} />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <FilterModule<OrderFilterType>
            filters={getFilters()}
            currentlyUpdating={currentlyUpdating}
            onFilterUpdate={handleFilterUpdate}
            onFilterDelete={handleFilterDelete}
          />

          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          <CardListPaginated
            data={ordersData?.data || []}
            pagination={{
              page,
              totalPages: ordersData?.pagination.totalPages || 0,
              totalCount: ordersData?.pagination.totalCount || 0,
              limit,
            }}
            element={(order: Order) => (
              <DirectOrderCard
                key={order.id}
                order={order}
                onClick={() => {
                  if (order.id) {
                    navigation.navigate('ProducerDirectOrder', { orderId: order.id })
                  }
                }}
              />
            )}
            emptyMessage="Aucune commande n'a été créée"
            onChangePage={onChangePage}
            isLoading={ordersLoading}
          />

          <Spacer fixedSize={32} axis={Spacer.AxisEnum.VERTICAL} />
          <SectionTitle title="Paniers en cours" />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <CardListPaginated
            data={currentCarts?.data || []}
            pagination={currentCarts?.pagination}
            element={(cart: CatalogCart) => (
              <DirectCartCard key={cart.id} cart={cart} onClick={onPressCart} />
            )}
            emptyMessage="Aucun panier en cours"
            onChangePage={onChangeCartsPage}
            isLoading={cartsLoading}
          />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerOrderListScreen
