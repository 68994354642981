import { View } from 'react-native'
import Typography from '../Typography'
import TouchableCard from '../TouchableCard'
import FormatUtils from '../../utilities/utils/format'
import DirectusUtil from '../../utilities/utils/directus'
import AddDirectCartButton from '../../modules/Cart/AddDirectCartButton'
import { DirectBatchCardNewProps } from './DirectBatchCardNew.model'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledImage,
  StyledCenterBox,
  StyledValuesWrapper,
  StyledValueBox,
  StyledRightBox,
} from './DirectBatchCardNew.styles'
import { Product } from '../../domain/Product'
import { CatalogItem } from '../../domain/catalog'
import Spacer from '../Spacer'

const getAvailablePackingQuantity = (catalogItem: CatalogItem) => {
  if (
    catalogItem.availableQuantity &&
    catalogItem.packing &&
    catalogItem.availableQuantity >= catalogItem.packing
  ) {
    return Math.round(catalogItem.availableQuantity / catalogItem.packing)
  }
  return 0
}

const DirectBatchCardNew = ({ catalogItem, cart, noButton, producer }: DirectBatchCardNewProps) => {
  if (!catalogItem || !cart) return null

  return (
    <StyledCardWrapper activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId(catalogItem?.product as any as Product)}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledCenterBox>
            <Typography.CardTitle colorName="text-dark-1" ellipsis>
              {catalogItem?.product?.label}
            </Typography.CardTitle>
            <Typography.BodyExtraSmall colorName="text-dark-3">
              {producer?.label}
            </Typography.BodyExtraSmall>
            <StyledValuesWrapper>
              <StyledValueBox>
                <Typography.BodySmall>Prix min.</Typography.BodySmall>
                <Typography.BodySmall semiBold>
                  {FormatUtils.formatPrice(catalogItem?.unitPriceProducer, undefined, producer)}/
                  {FormatUtils.formatUnity(catalogItem?.product?.mesureType)}
                </Typography.BodySmall>
              </StyledValueBox>
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
              <StyledValueBox>
                <Typography.BodySmall>Prix client</Typography.BodySmall>
                <Typography.BodySmall semiBold>
                  {FormatUtils.formatPrice(catalogItem?.unitPrice)}/
                  {FormatUtils.formatUnity(catalogItem?.product?.mesureType)}
                </Typography.BodySmall>
              </StyledValueBox>
            </StyledValuesWrapper>
          </StyledCenterBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3">
                par{' '}
                {FormatUtils.formatQuantity(catalogItem?.packing, catalogItem?.product?.mesureType)}
              </Typography.BodySmall>
              {getAvailablePackingQuantity(catalogItem) > 0 && (
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  Dispo. : {getAvailablePackingQuantity(catalogItem)} colis
                </Typography.BodyExtraSmall>
              )}
            </View>
            <View>
              {!noButton && <AddDirectCartButton cart={cart} catalogItem={catalogItem} small />}
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default DirectBatchCardNew
