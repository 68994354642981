import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Button, SectionTitle, Spacer, TextInput } from '../../../components'
import { Client, ClientGroup, Group, Pagination } from '../../../domain'
import i18n from '../../../i18n'
import ClientCard from '../../../modules/Client/ClientCard'
import GroupCarrierService from '../../../services/carrier/group'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import Loader from '../../../components/Loader'
import CardListPaginated from '../../../components/CardListPaginated'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import { TextInputWrapper } from './CarrierGroupClients.styles'

const CarrierGroupClientsScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [pageSizeClientGroups, setPageSizeClientGroups] = useState(5)
  const [pageNumberClientGroups, setPageNumberClientGroups] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group'],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: clientGroups,
    refetch: refetchClientGroups,
    isLoading: isClientGroupsLoading,
  } = useQuery<{ data: ClientGroup[]; pagination: Pagination }, Error>(
    ['sf_group_clients'],
    () =>
      GroupCarrierService.getOneGroupClients(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        pageSizeClientGroups,
        pageNumberClientGroups,
        searchValue,
      ),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      refetchClientGroups()
    }
  }, [searchValue])

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    setPageNumberClientGroups(1)
  }

  const OnClickAddClient = () => {
    if (!groupData?.group?.id) return
    navigation.navigate('CarrierAddClient', { groupId: groupData?.group.id })
  }

  const onClickClient = (client: Client) => {
    navigation.navigate('CarrierUpdateClient', { id: client.id })
  }

  if (isClientGroupsLoading || !groupData?.group) {
    return <Loader isLoading pageLoading />
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberClientGroups(pageNumber)
    refetchClientGroups()
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <SectionTitle
          title={`Clients (${clientGroups?.pagination?.totalCount || 0})`}
          buttonRight={
            <Button
              small
              label={FormatUtils.capitalize(i18n.t('add'))}
              onPress={() => OnClickAddClient()}
            />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={onChangeSearchValue}
            small
          />
        </TextInputWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        <CardListPaginated
          data={clientGroups?.data}
          pagination={clientGroups?.pagination}
          element={function (clientGroup: any) {
            return (
              <ClientCard
                key={clientGroup.id}
                group={groupData?.group}
                client={clientGroup.client}
                onClick={onClickClient}
                hasClientTypeLabel
              />
            )
          }}
          isLoading={isClientGroupsLoading}
          emptyMessage="Aucun client dans votre groupe"
          onChangePage={onChangePage}
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierGroupClientsScreen
