import { useState } from 'react'
import { FilterModuleProps, Filter, InvoiceFilterEnum, SelectedValues } from './FilterModule.model'
import Typography from '../Typography'
import {
  ButtonsContainer,
  DropdownContainer,
  DropdownWrapper,
  FilterModuleWrapper,
  FilterOptionsContainer,
  StyledButtonWrapper,
} from './FilterModule.styles'
import Dropdown from '../Dropdown'
import Button from '../Button'
import Spacer from '../Spacer'

const FilterModule = <T extends number | string>({
  filters = [],
  currentlyUpdating,
  onFilterUpdate,
  onFilterDelete,
  formatFilterLabel = (key: T) => {
    return String(key)
  },
}: FilterModuleProps<T>) => {
  type FilterValues = {
    [K in T]: any
  }
  const [localValues, setLocalValues] = useState<Partial<FilterValues>>({})

  if (!Array.isArray(filters)) {
    return null
  }

  const handleFilterPress = (filter: Filter<T>) => {
    onFilterUpdate(filter.key)
  }

  const handleValueChange = (filterKey: T) => (items: any) => {
    const selectedValue = Array.isArray(items) ? items[0]?.value : items?.value
    setLocalValues((prev) => ({ ...prev, [filterKey]: selectedValue }))
  }

  const handleValidate = (filterKey: T) => {
    const valueToSend = localValues[filterKey]
    onFilterUpdate(filterKey, valueToSend)
  }

  const handleDelete = (filterKey: T) => {
    setLocalValues((prev) => {
      const newValues = { ...prev }
      delete newValues[filterKey]
      return newValues
    })

    onFilterDelete(filterKey)
  }

  return (
    <FilterModuleWrapper>
      <FilterOptionsContainer>
        <Typography.BodySmall semiBold colorName="text-dark-3">
          Filtres :
        </Typography.BodySmall>
        <ButtonsContainer>
          {filters.map((filter) => (
            <Button.Icon
              key={filter.key}
              label={filter.label}
              iconName="pencil"
              iconType="MaterialCommunityIcons"
              iconPosition="right"
              onPress={() => handleFilterPress(filter)}
              colorName={filter.active ? 'color-secondary' : 'color-white'}
              small
              lowPadding
              noMargin
              bordered
            />
          ))}
        </ButtonsContainer>
      </FilterOptionsContainer>
      <DropdownWrapper>
        {filters.map(
          (filter) =>
            currentlyUpdating === filter.key && (
              <DropdownContainer key={filter.key}>
                <Typography.BodyExtraSmall bold colorName="text-dark-3">
                  Filtrer par {formatFilterLabel?.(filter.key)}
                </Typography.BodyExtraSmall>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <Dropdown
                  label={`Choisir un ${formatFilterLabel?.(filter.key)}`}
                  onChange={(items) => {
                    handleValueChange(filter.key)(items)
                  }}
                  optionsDefault={filter.options}
                  defaultValue={localValues[filter.key]}
                  zIndex={100000}
                  isSearchable={filter.key === InvoiceFilterEnum.CLIENT}
                />
                <StyledButtonWrapper>
                  <Button
                    label="Supprimer"
                    colorName="color-danger"
                    onPress={() => handleDelete(filter.key)}
                    fixedWidth={45}
                  />
                  <Button
                    label="Valider"
                    onPress={() => handleValidate(filter.key)}
                    fixedWidth={45}
                  />
                </StyledButtonWrapper>
              </DropdownContainer>
            ),
        )}
      </DropdownWrapper>
    </FilterModuleWrapper>
  )
}

export default FilterModule
