import { Client, Tour } from '../../../domain'
import ApiService from '../../api.service'

const create = async (groupId: string, newClient: any): Promise<Tour> => {
  const response = await ApiService.post(`client/group/${groupId}`, newClient).catch((error) => {
    throw error
  })
  return response
}

const update = async (clientId: string, client: any): Promise<Tour> => {
  const response = await ApiService.patch(`/client/${clientId}`, client).catch((error) => {
    throw error
  })
  return response
}

const getOneClient = async (
  carrierId: string,
  clientId?: string,
  mail?: string,
): Promise<Client> => {
  const response = await ApiService.get(`/client/carrier/${carrierId}`, {
    mail,
    id: clientId,
  }).catch((error) => {
    throw error
  })
  return response
}

const linkClient = async (clientId: string, groupId: string): Promise<Client> => {
  const response = await ApiService.patch(`/client/group/${groupId}/client/${clientId}`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const deleteUserClient = async (
  userId: string,
  clientId: string,
  groupId: string,
): Promise<Client> => {
  const response = await ApiService.remove(
    `/client/user/${userId}/client/${clientId}/group/${groupId}/user-client`,
  ).catch((error) => {
    throw error
  })
  return response
}

const ClientCarrierService = {
  create,
  update,
  getOneClient,
  linkClient,
  deleteUserClient,
}

export default ClientCarrierService
