import { useTheme } from 'styled-components/native'
import React, { useState } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { View } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button, Spacer, SectionTitle, Typography, TextInput } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { StyledButtonWrapper, ToursContentWrapper } from './ProducerDirectCartAddScreen.styles.'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import CartProducerService from '../../../services/producer/cart'
import { Producer } from '../../../domain/Producer/producer.model'
import { CatalogItem, CatalogResponse } from '../../../domain/catalog'
import DirectBatchCardNew from '../../../components/DirectBatchCardNew'
import CardListPaginated from '../../../components/CardListPaginated'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import DirectOrderTopBox from '../../../components/DirectOrderTopBox'
import Loader from '../../../components/Loader'
import ProducerProducerService from '../../../services/producer/producer'
import { TextInputWrapper } from '../ProducerPropositionsV2/ProducerPropositionsV2.styles'
import TotalSummaryCard from '../../../components/TotalSummaryCard'
import CartClientService from '../../../services/client/cart'
import { queryClient } from '../../../utilities/queryClient'

const ProducerDirectCartUpdateScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerDirectCartUpdate'>>()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const cartId = route.params?.cartId

  const [pageSize, setPageSize] = useState(4)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [isNewOrder, setIsNewOrder] = useState(true)

  const {
    data: producerData,
    refetch: refetchProducer,
    isFetching: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  const {
    data: catalogData,
    isLoading,
    refetch: refetchCatalog,
  } = useQuery<CatalogResponse>(
    ['direct_catalog', cartId],
    () =>
      CartProducerService.getDirectCatalog({
        cartId,
        page: pageNumber,
        limit: pageSize,
        search: searchValue.length >= 3 ? searchValue : undefined,
      }),
    {
      enabled: !!cartId,
      keepPreviousData: true,
      select: (data) => ({
        ...data,
        catalogItems: data.catalogItems.filter((item) => item.id),
      }),
    },
  )

  const onValidateOrder = async () => {
    await CartClientService.createDirectOrder(cartId, true)
    navigation.navigate('ProducerOrderList', undefined)
    await queryClient.invalidateQueries({
      queryKey: ['producer_orders'],
    })
    await queryClient.invalidateQueries({
      queryKey: ['current_direct_carts'],
    })
  }

  const totalSummaryItems = [
    {
      totalHT: catalogData?.cart?.totalAmounts.totalHT || 0,
      totalVAT:
        (catalogData?.cart?.totalAmounts.totalVAT55 || 0) +
        (catalogData?.cart?.totalAmounts.totalVAT10 || 0) +
        (catalogData?.cart?.totalAmounts.totalVAT20 || 0),
      totalTTC: catalogData?.cart?.totalAmounts.totalTTC || 0,
    },
  ]

  if (isLoading || !catalogData?.cart) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <DirectOrderTopBox cart={catalogData.cart} />
      <ContentWrapper marginTopLarge>
        <ToursContentWrapper>
          <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
          <SectionTitle
            title={!isNewOrder ? 'Contenu de la commande' : "Création d'une commande"}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          {catalogData.catalogItems.length < 1 && (
            <View style={{ alignItems: 'center', width: '100%' }}>
              <Spacer fixedSize={2} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall align="center">
                Vous n&apos;avez aucun lot de produit disponible dans votre mercuriale
              </Typography.BodySmall>
            </View>
          )}
          <CardListPaginated
            data={catalogData.catalogItems}
            pagination={catalogData.pagination}
            element={(item: CatalogItem) => (
              <DirectBatchCardNew
                key={item.batch.id}
                catalogItem={item}
                cart={catalogData.cart}
                producer={producerData?.producer}
              />
            )}
          />

          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <StyledButtonWrapper>
              <Button
                label="Ajouter un lot"
                onPress={() =>
                  navigation.navigate('ProducerDirectCartCatalog', {
                    cartId,
                  })
                }
              />
            </StyledButtonWrapper>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <TotalSummaryCard items={totalSummaryItems} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <StyledButtonWrapper>
              <Button
                label={isNewOrder ? 'Valider la commande' : 'Modifier la commande'}
                onPress={() => onValidateOrder()}
              />
            </StyledButtonWrapper>
          </>

          <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerDirectCartUpdateScreen
