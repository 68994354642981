/* eslint-disable no-nested-ternary */
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { ClientTypeEnum } from '../../../../enums/client'
import {
  Button,
  Card,
  Col,
  Icon,
  Icons,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { AddClient, Client } from '../../../domain'
import i18n from '../../../i18n'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import GeneralUtils from '../../../utilities/utils/general'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import {
  BackToListWrapper,
  LeftCardContentWrapper,
  StyledButtonWrapper,
  StyledCardContentWrapper,
  StyledCardWrapper,
  ToursContentWrapper,
} from './ProducerAddClient.styles'
import ProducerClientService from '../../../services/client/producer'
import CommercialAgreementService from '../../../services/commercial-agreement'
import { CommercialAgreement, NewCommercialAgreement } from '../../../domain/CommercialAgreement'

const ProducerAddClientScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessInfos } = useAuthenticationContext()

  const theme = useTheme()
  const [newClient, setNewClient] = useState<AddClient>({
    label: '',
    owners: '',
    phone1: '',
    phone2: '',
    mail: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
  })
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [clientMailRequest, setClientMailRequest] = useState<string>('')
  const [foundClient, setFoundClient] = useState<Client>()
  const [foundCommercialAgreement, setFoundCommercialAgreement] = useState<CommercialAgreement>()
  const [displayNoProfile, setDisplayNoProfile] = useState<boolean>(false)
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false)
  const [newCommercialAgreement, setNewCommercialAgreement] = useState<NewCommercialAgreement>({
    carrierFees: '',
  })
  const [carrierFeesError, setCarrierFeesError] = useState<string>('')
  const [carrierFeesValid, setCarrierFeesValid] = useState<boolean>(true)

  // eslint-disable-next-line consistent-return
  const setValue = (value: any, param: string) => {
    if (!param) return null
    console.log('HERE')
    setErrorMessage('')
    if (param === 'carrierFees') {
      // Update commercial agreement
      setNewCommercialAgreement({
        carrierFees: value,
      })

      const cF = FormatUtils.convertCarrierFeesToDecimal(value)
      if (cF >= 2) {
        setCarrierFeesValid(false)
        setCarrierFeesError(
          'Merci de modifier le montant de la commission qui doit être inférieur à 200%',
        )
      } else {
        setCarrierFeesValid(true)
        setCarrierFeesError('')
      }
    } else {
      // Update newClient regardless of whether it's a new or found client
      const clientTemp = { ...newClient }
      clientTemp[param as keyof typeof newClient] = value
      setNewClient(clientTemp)
    }
  }

  const onAddClient = async () => {
    try {
      setIsLoading(true)
      setErrorMessage('')

      if (newClient.mail && !GeneralUtils.isEmail(newClient.mail)) {
        setErrorMessage(`Le format de l'email saisi est invalide`)
        return
      }

      const cF = FormatUtils.convertCarrierFeesToDecimal(newCommercialAgreement.carrierFees)

      if (cF >= 2) {
        setCarrierFeesValid(false)
        setCarrierFeesError(
          'Merci de modifier le montant de la commission qui doit être inférieur à 200%',
        )
        return
      }

      let clientId: string

      if (foundClient) {
        // If we have a foundClient, use its ID
        clientId = foundClient.id
      } else {
        // Only create a new client if we don't have a foundClient
        if (!newClient.label) {
          setErrorMessage('Le label est requis')
          return
        }

        const client = {
          clientType: ClientTypeEnum.RETAILER,
          label: newClient.label,
          owners: newClient.owners,
          phone1: newClient.phone1,
          phone2: newClient.phone2,
          mail: newClient.mail,
          addressLine1: newClient.addressLine1,
          addressLine2: newClient.addressLine2,
          postalCode: newClient.postalCode,
          city: newClient.city,
        }

        if (client.mail && !GeneralUtils.isEmail(client.mail)) {
          setErrorMessage(`Le format de l'email saisi est invalide`)
          return
        }

        const createdClient = await ProducerClientService.create(client)
        clientId = createdClient.id
      }

      // Create commercial agreement using the appropriate clientId
      const commercialAgreement = {
        clientId,
        carrierFees: cF,
      }

      const createdCommercialAgreement = await CommercialAgreementService.create(
        commercialAgreement,
      )

      if (createdCommercialAgreement) {
        // Invalidate and refetch the mixed clients query
        await queryClient.invalidateQueries({
          queryKey: ['commercial-agreement'],
        })

        navigation.navigate('ProducerClients', {})
      } else {
        setErrorMessage(
          'Un problème est survenu lors de la récuparation des données de votre compte',
        )
      }
    } catch (error) {
      console.error('Error in onAddClient:', error)
      setErrorMessage('Un problème est survenu lors de la création')
    } finally {
      setIsLoading(false)
    }
  }

  const onRequestSearchMail = async () => {
    if (clientMailRequest && !GeneralUtils.isEmail(clientMailRequest)) {
      setErrorMessage(`Le format de l'email saisi est invalide`)
      setIsLoading(false)
      return
    }

    try {
      setIsLoading(true)
      const foundExistingClient = await ProducerClientService.getByMail(clientMailRequest)

      if (foundExistingClient) {
        setFoundClient(foundExistingClient)

        try {
          const commercialAgreements = await CommercialAgreementService.getByProducer(
            getAccessInfos().producerId,
          )

          const existingAgreement = commercialAgreements?.find(
            (agreement) => agreement.client.id === foundExistingClient.id,
          )

          if (existingAgreement) {
            setFoundCommercialAgreement(existingAgreement)
          }
          // Remove the setFormIsOpen here - let the user click "Lier" first
        } catch (error) {
          console.error('Error checking commercial agreement:', error)
          setErrorMessage('Une erreur est survenue lors de la vérification des accords commerciaux')
        }
      } else {
        setNewClient((prev) => ({
          ...prev,
          mail: clientMailRequest,
        }))
        setDisplayNoProfile(true)
        setFormIsOpen(true)
      }
    } catch (error) {
      console.error('Error searching for client:', error)
      setErrorMessage('Une erreur est survenue lors de la recherche')
    } finally {
      setIsLoading(false)
    }
  }

  const handleBackToList = () => {
    setClientMailRequest('')
    setFoundClient(undefined)
    setFoundCommercialAgreement(undefined)
    setFormIsOpen(false)
    setDisplayNoProfile(false)
    setErrorMessage('')
    setNewClient({
      label: '',
      owners: '',
      phone1: '',
      phone2: '',
      mail: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      city: '',
    })
    setNewCommercialAgreement({
      carrierFees: '',
    })
  }

  const onValidateClient = async (foundedClient: Client) => {
    setErrorMessage('')

    if (foundedClient) {
      try {
        setIsLoading(true)
        setNewClient({
          label: foundedClient.label || '',
          owners: foundedClient.owners || '',
          phone1: foundedClient.phone1 || '',
          phone2: foundedClient.phone2 || '',
          mail: foundedClient.mail || '',
          addressLine1: foundedClient.addressLine1 || '',
          addressLine2: foundedClient.addressLine2 || '',
          postalCode: foundedClient.postalCode || '',
          city: foundedClient.city || '',
        })
        setFormIsOpen(true)
      } catch (error) {
        setErrorMessage('Un problème est survenu lors de la liaison du client')
        console.error(error)
      }
    }
    setIsLoading(false)
    setDisplayNoProfile(false)
    setFormIsOpen(true)
  }

  const clientData = foundClient || newClient

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Ajouter un client" />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          {!formIsOpen && (
            <>
              {foundClient && (
                <BackToListWrapper onPress={handleBackToList}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-back-outline"
                    color={theme.colors['color-primary']}
                  />
                  <Button.Text label="Revenir en arrière" onPress={handleBackToList} />
                </BackToListWrapper>
              )}
              <SectionTitle title="Saisir un email" />
              <Row rowStyles={{ justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={9}>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <TextInput
                    value={clientMailRequest}
                    label="Email"
                    field="text"
                    onChangeText={(text) => {
                      setClientMailRequest(text)
                      setFoundClient(undefined)
                      setErrorMessage('')
                      setDisplayNoProfile(false)
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <StyledButtonWrapper>
                    {!foundClient && !displayNoProfile && (
                      <Button
                        label="Vérifier"
                        onPress={() => onRequestSearchMail()}
                        loading={isLoading}
                      />
                    )}
                  </StyledButtonWrapper>
                </Col>
              </Row>
              <Row>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                {errorMessage ? (
                  <>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                    <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  </>
                ) : null}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              </Row>
              {foundClient && !foundCommercialAgreement ? (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  <Typography.Body colorName="color-grey">
                    Nous avons trouvé ce client dans notre base de donnée. Vous pouvez le rattacher
                    à votre compte en cliquant sur le bouton correspondant.
                  </Typography.Body>
                  <SectionTitle title="Client" />
                  <Row>
                    <Col xs={12}>
                      <StyledCardWrapper>
                        <Card>
                          <StyledCardContentWrapper>
                            <LeftCardContentWrapper>
                              <Typography.CardTitle>{foundClient?.label}</Typography.CardTitle>
                              <Typography.Body>
                                {foundClient.clientGroups?.map((cg) => cg.group?.label)}
                              </Typography.Body>
                            </LeftCardContentWrapper>
                            <StyledButtonWrapper>
                              <Button
                                label="Lier"
                                onPress={() => onValidateClient(foundClient)}
                                loading={isLoading}
                              />
                            </StyledButtonWrapper>
                          </StyledCardContentWrapper>
                        </Card>
                      </StyledCardWrapper>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )}
          {foundClient && foundCommercialAgreement ? (
            <Typography.Body colorName="color-grey">
              Le client {foundClient.label} est déjà associé à votre compte.
            </Typography.Body>
          ) : (formIsOpen && foundClient) || (displayNoProfile && formIsOpen) ? (
            <>
              <BackToListWrapper onPress={handleBackToList}>
                <Icon
                  type={Icons.Ionicons}
                  name="chevron-back-outline"
                  color={theme.colors['color-primary']}
                />
                <Button.Text label="Revenir en arrière" onPress={handleBackToList} />
              </BackToListWrapper>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <SectionTitle title="Informations client" />
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.label}
                    label="Nom de l'enseigne"
                    field="text"
                    onChangeText={(text) => setValue(text, 'label')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.owners}
                    label="Gérant.e.s"
                    field="text"
                    onChangeText={(text) => setValue(text, 'owners')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.mail}
                    label="Email"
                    field="text"
                    onChangeText={(text) => setValue(text, 'mail')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.phone1}
                    label="Téléphone (0656...)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'phone1')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.addressLine1}
                    label="N°, rue"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine1')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.addressLine2}
                    label="Complément d'adresse"
                    field="text"
                    onChangeText={(text) => setValue(text, 'addressLine2')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.postalCode}
                    label="Code postal"
                    field="text"
                    onChangeText={(text) => setValue(text, 'postalCode')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={clientData.city}
                    label="Ville"
                    field="text"
                    onChangeText={(text) => setValue(text, 'city')}
                  />
                </Col>
              </Row>
              <SectionTitle title="Commission logistique (en %)" />
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newCommercialAgreement.carrierFees}
                    label="Commission logistique (en %)"
                    field="text"
                    onChangeText={(text) => setValue(text, 'carrierFees')}
                    hasError={!carrierFeesValid}
                  />
                </Col>
              </Row>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <StyledButtonWrapper>
                {errorMessage ? (
                  <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                ) : null}
                {carrierFeesError ? (
                  <>
                    <Typography.Body colorName="color-danger">{carrierFeesError}</Typography.Body>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                  </>
                ) : null}
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                <Button
                  label={FormatUtils.capitalize(i18n.t('add'))}
                  onPress={() => onAddClient()}
                  loading={isLoading}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              </StyledButtonWrapper>
            </>
          ) : null}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerAddClientScreen
