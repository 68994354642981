/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable prefer-object-spread */
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { ItemType, ValueType } from 'react-native-dropdown-picker'
import { CLIENT_TYPES } from '../../../../enums/client'
import {
  Button,
  Col,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import { UpdateClient, Client, AddressType, Address } from '../../../domain'
import i18n from '../../../i18n'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import LabelForm from '../../../modules/Common/LabelForm'
import { LabelEnums } from '../../../../enums'
import GeneralUtils from '../../../utilities/utils/general'
import AddressForm from '../../../modules/Address/AddressForm'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import ClientClientService from '../../../services/client/client'
import AccountService from '../../../services/billing/account'
import { ToursContentWrapper } from '../../Carrier/CarrierUpdateClient/CarrierUpdateClient.styles'
import { StyledButtonWrapper } from '../../Carrier/CarrierTourDeliveryStop/CarrierTourDeliveryStop.styles'
import CommercialAgreementService from '../../../services/commercial-agreement'
import { queryClient } from '../../../utilities/queryClient'

interface FormState {
  updatedClient: UpdateClient | undefined
  selectedClientType:
    | {
        label: string
        value: number
      }
    | undefined
  carrierFeesError: string
  errorMessage: string
  isLoading: boolean
}

const ProducerUpdateClientScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerUpdateClient'>>()

  const { commercialAgreementId } = route?.params ?? {
    commercialAgreementId: '',
  }

  const { data: commercialAgreement } =
    CommercialAgreementService.useGetCommercialAgreement(commercialAgreementId).query()

  const clientId = commercialAgreement?.client.id

  const {
    data: clientData,
    refetch: refetchClient,
    isInitialLoading: isClientLoading,
  } = useQuery<Client, Error>(
    ['p_update_client', clientId],
    () => {
      if (!clientId) throw new Error('No client ID available')
      return ClientClientService.getOne(clientId, true)
    },
    {
      keepPreviousData: true,
      enabled: !!clientId,
    },
  )

  const [formState, setFormState] = useState<FormState>({
    updatedClient: undefined,
    selectedClientType: undefined,
    carrierFeesError: '',
    errorMessage: '',
    isLoading: false,
  })

  const setValue = (value: any, param: string) => {
    setFormState((prev) => ({
      ...prev,
      errorMessage: '',
      updatedClient: prev.updatedClient
        ? ({
            ...prev.updatedClient,
            [param]: value,
            account: prev.updatedClient.account ? { ...prev.updatedClient.account } : null,
          } as UpdateClient)
        : undefined,
    }))
  }

  useEffect(() => {
    if (!clientData?.account) return

    const safeClientData = {
      ...clientData,
      account: clientData.account
        ? {
            ...clientData.account,
            address: clientData.account.address ? { ...clientData.account.address } : null,
          }
        : null,
      carrierFees: FormatUtils.displayCarrierFees(commercialAgreement?.carrierFees),
    }

    const clientType = CLIENT_TYPES.find((mt) => mt.value === clientData?.clientType)

    setFormState((prev) => ({
      ...prev,
      updatedClient: safeClientData as unknown as UpdateClient,
      selectedClientType: clientType ? { ...clientType } : undefined,
    }))
  }, [clientId, clientData, commercialAgreement])

  const onChangeClientType = useCallback((items: ItemType<ValueType>[]) => {
    if (items?.[0]?.label && items[0]?.value !== undefined) {
      setFormState((prev) => ({
        ...prev,
        selectedClientType: {
          label: items[0].label as string,
          value: items[0].value as number,
        },
        errorMessage: '',
      }))
    }
  }, [])

  const clientToUpdate = useMemo(() => {
    if (!formState.updatedClient || !formState.selectedClientType) return null

    return {
      clientType: formState.selectedClientType.value,
      label: formState.updatedClient.label,
      owners: formState.updatedClient.owners,
      phone1: formState.updatedClient.phone1,
      phone2: formState.updatedClient.phone2,
      mail: formState.updatedClient.mail,
      addressLine1: formState.updatedClient.addressLine1,
      addressLine2: formState.updatedClient.addressLine2,
      postalCode: formState.updatedClient.postalCode,
      city: formState.updatedClient.city,
      labels: formState.updatedClient.labels,
    }
  }, [formState.updatedClient, formState.selectedClientType])

  const onChangeDeliveryAddress = useCallback(
    (newAddress: Address) => {
      if (newAddress && newAddress.id) {
        setFormState((prev) => ({
          ...prev,
          updatedClient: prev.updatedClient
            ? ({
                ...prev.updatedClient,
                deliveryAddress: newAddress,
              } as UpdateClient)
            : undefined,
        }))
        refetchClient()
      }
    },
    [refetchClient],
  )

  const onChangeBillingAddress = useCallback(
    async (newAddress: Address) => {
      if (newAddress && newAddress.id && clientData?.account?.id) {
        await AccountService.update(clientData.account.id, {
          address: { id: newAddress.id },
        })
        await refetchClient()
      }
    },
    [clientData?.account?.id, refetchClient],
  )

  const onUpdateClient = async () => {
    if (!clientId) return
    setFormState((prev) => ({ ...prev, errorMessage: '', isLoading: true }))

    try {
      if (!clientToUpdate) {
        throw new Error('Invalid client data')
      }

      if (!GeneralUtils.isEmail(clientToUpdate.mail!)) {
        setFormState((prev) => ({
          ...prev,
          errorMessage: `Le format de l'email saisi est invalide`,
          isLoading: false,
        }))
        return
      }

      const updatedClientTemp = await ClientClientService.update(clientId, clientToUpdate)

      if (updatedClientTemp && updatedClientTemp.id) {
        // Update commercial agreement with carrier fees
        if (commercialAgreementId && formState.updatedClient?.carrierFees !== undefined) {
          const updatedCarrierFees = formState.updatedClient.carrierFees
          const carrierFees = FormatUtils.convertCarrierFeesToDecimal(updatedCarrierFees)

          try {
            if (carrierFees >= 2) {
              throw new Error(
                'Merci de modifier le montant de la commission qui doit être inférieur à 200%',
              )
            }

            await CommercialAgreementService.update(commercialAgreementId, {
              carrierFees,
            })

            queryClient.invalidateQueries([
              'commercial-agreement',
              `commercialAgreementId_${commercialAgreementId}`,
            ])
          } catch (error) {
            setFormState((prev) => ({
              ...prev,
              errorMessage:
                error instanceof Error && error.message.includes('Merci de modifier')
                  ? error.message
                  : 'Un problème est survenu lors de la mise à jour de la commission logistique',
              isLoading: false,
            }))
            return
          }
        }

        await queryClient.fetchQuery(['p_client', clientId])
        navigation.navigate('ProducerClient', { clientId, commercialAgreementId })
      } else {
        throw new Error('Update failed')
      }
    } catch (error) {
      setFormState((prev) => ({
        ...prev,
        errorMessage: 'Un problème est survenu lors de la mise à jour du client',
        isLoading: false,
      }))
      return
    }

    setFormState((prev) => ({ ...prev, isLoading: false }))
  }

  if (!formState.updatedClient) return <View />

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle title="Modifier un client" />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Dropdown
              label="Type de client"
              onChange={onChangeClientType}
              multiple={false}
              optionsDefault={CLIENT_TYPES.map((clientType) => ({
                ...clientType,
              }))}
              zIndex={200}
              itemKey="clientType"
              defaultValue={formState.selectedClientType?.value}
            />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={formState.updatedClient.label}
                  label="Nom du client"
                  field="text"
                  onChangeText={(text) => setValue(text, 'label')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={formState.updatedClient.owners}
                  label="Gérant.e.s"
                  field="text"
                  onChangeText={(text) => setValue(text, 'owners')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={formState.updatedClient.mail}
                  label="Email"
                  field="text"
                  onChangeText={(text) => setValue(text, 'mail')}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={formState.updatedClient.phone1}
                  label="Téléphone (0656...)"
                  field="text"
                  onChangeText={(text) => setValue(text, 'phone1')}
                />
              </Col>
            </Row>
            {formState.updatedClient.deliveryAddress && (
              <AddressForm
                sectionTitle="Adresse de livraison"
                onChangeAddress={onChangeDeliveryAddress}
                address={formState.updatedClient.deliveryAddress}
                addressType={AddressType.DELIVERY}
                client={clientData}
              />
            )}
            {!formState.updatedClient.deliveryAddress && (
              <>
                <SectionTitle title="Adresse de livraison" />
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={formState.updatedClient.addressLine1}
                      label="N°, rue"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine1')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={formState.updatedClient.addressLine2}
                      label="Complément d'adresse"
                      field="text"
                      onChangeText={(text) => setValue(text, 'addressLine2')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={formState.updatedClient.postalCode}
                      label="Code postal"
                      field="text"
                      onChangeText={(text) => setValue(text, 'postalCode')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={formState.updatedClient.city}
                      label="Ville"
                      field="text"
                      onChangeText={(text) => setValue(text, 'city')}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <AddressForm
              sectionTitle="Adresse de facturation"
              onChangeAddress={onChangeBillingAddress}
              address={formState.updatedClient?.account?.address || undefined}
              addressType={AddressType.BILLING}
              client={formState.updatedClient}
            />
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title="Commission logistique (en %, inférieur à 200)" />
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Col xs={12} sm={12} md={6}>
              <TextInput
                value={formState.updatedClient.carrierFees}
                label="Commission logistique (en %)"
                field="number"
                onChangeText={(text) => setValue(text, 'carrierFees')}
                editable
                hasError={
                  FormatUtils.convertCarrierFeesToDecimal(
                    formState.updatedClient.carrierFees || '',
                  ) >= 2
                }
              />
              {formState.carrierFeesError && (
                <>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                  <Typography.Body colorName="color-danger">
                    {formState.carrierFeesError}
                  </Typography.Body>
                </>
              )}
            </Col>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <LabelForm
              labels={formState.updatedClient.labels}
              validLabels={[LabelEnums.BIOCOOP]}
              type={1}
              onChangeLabel={(labels) => setValue(labels, 'labels')}
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            {formState.errorMessage ? (
              <Typography.Body colorName="color-danger">{formState.errorMessage}</Typography.Body>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

            <Button
              label={FormatUtils.capitalize(i18n.t('save'))}
              onPress={onUpdateClient}
              loading={formState.isLoading}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir modifier ce client ?"
            />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerUpdateClientScreen
