import {
  CardWrapper,
  StyledLeftBox,
  StyledTopBox,
  TourTopBoxWrapper,
} from './DirectOrderTopBox.styles'

import { DirectOrderTopBoxProps } from './DirectOrderTopBox.model'
import Typography from '../Typography'
import Spacer from '../Spacer'
import FormatUtils from '../../utilities/utils/format'
import TouchableCard from '../TouchableCard'
import { Order } from '../../domain'
import DeviceUtil from '../../utilities/utils/device'

function DirectOrderTopBox({ cart }: DirectOrderTopBoxProps) {
  const getOrderIdFormatted = (order?: Order) => {
    if (order && order.id && order.id.length > 5) {
      return order.id.slice(-5)
    }
    return 'NA'
  }

  const isDesktop = !DeviceUtil.isMobileApp()

  return (
    <TourTopBoxWrapper isDesktop={isDesktop}>
      <CardWrapper>
        <TouchableCard>
          <StyledTopBox>
            <StyledLeftBox>
              {cart?.order ? (
                <>
                  <Typography.Body bold>
                    Commande #{getOrderIdFormatted(cart?.order)}
                  </Typography.Body>
                  <Typography.BodyExtraSmall>
                    Validée le {FormatUtils.formatDate(cart?.order?.createdDate, 'DateShort')} à{' '}
                    {FormatUtils.formatDate(cart?.order?.createdDate, 'Time')}
                  </Typography.BodyExtraSmall>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  <Typography.Body bold>{cart?.client?.label}</Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                  <Typography.BodySmall>
                    {FormatUtils.formatPrice(cart?.totalAmounts.totalHT || 0, 'ht')}
                  </Typography.BodySmall>
                </>
              ) : (
                <>
                  <Typography.Body bold>Nouvelle commande</Typography.Body>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
                  <Typography.BodySmall>
                    Livraison le {FormatUtils.formatDate(cart?.deliveryStart, 'DateShort')}
                  </Typography.BodySmall>
                  <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
                  <Typography.Body bold>{cart?.client?.label}</Typography.Body>
                </>
              )}
            </StyledLeftBox>
          </StyledTopBox>
        </TouchableCard>
      </CardWrapper>
    </TourTopBoxWrapper>
  )
}

export default DirectOrderTopBox
