import { InvoiceStatusEnum } from '../../../enums/invoice'

export interface Filter<T extends number | string> {
  key: T
  active: boolean
  label: string
  options: { label: string; value: any }[]
}

export interface FilterModuleProps<T extends number | string> {
  filters: Filter<T>[]
  currentlyUpdating: T | null
  onFilterUpdate: (filterOption: T, selectedValue?: any) => void
  onFilterDelete: (filterOption: T) => void
  formatFilterLabel?: (filterKey: T) => string
}

export enum InvoiceFilterEnum {
  CLIENT = 1,
  STATUS = 5,
}

export enum ProducerClientsFilterEnum {
  TYPE = 1,
  STATUS = 5,
}

export enum CommercialAgreementType {
  DIRECT = 1,
  GROUP = 2,
  BOTH = 3,
}

export enum ProducerClientsStatus {
  ACTIVE = 1,
  UNACTIVE = 2,
  BOTH = 3,
}

export type SelectedValues = {
  [InvoiceFilterEnum.CLIENT]?: string
  [InvoiceFilterEnum.STATUS]?: InvoiceStatusEnum
}

export type ProducerClientSelectedValues = {
  [ProducerClientsFilterEnum.TYPE]?: CommercialAgreementType
  [ProducerClientsFilterEnum.STATUS]?: ProducerClientsStatus
}
