import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { useQuery } from '@tanstack/react-query'
import { StackNavigationProp } from '@react-navigation/stack'
import { Linking, View } from 'react-native'
import { Button, PageTitle, SectionTitle, Spacer, Typography } from '../../../components'
import { DeliveryNote, Order, OrderType } from '../../../domain'
import BatchCartCard from '../../../modules/Batch/BatchCartCard'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { ToursContentWrapper } from './ProducerDirectOrder.styles'
import FormatUtils from '../../../utilities/utils/format'
import OrderHistory from '../../../modules/Order/OrderHistory'
import { UserEnums } from '../../../../enums'
import { GroupEnums } from '../../../../enums/group'
import useGroupContext from '../../../utilities/hook/useGroupContext'
import CardSettings from '../../../components/CardSettings'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import OrderClientService from '../../../services/client/order'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import OrderClientTopBox from './components/OrderClientTopBox'
import { queryClient } from '../../../utilities/queryClient'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import DirectusUtil from '../../../utilities/utils/directus'

const ProducerDirectOrderScreen = () => {
  const theme = useTheme()
  const { group } = useGroupContext()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerDirectOrder'>>()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { getAccessInfos } = useAuthenticationContext()

  const { orderId } = route?.params ?? {
    orderId: '',
  }

  const { data: order } = useQuery<Order, Error>(
    ['c_order'],
    () => OrderClientService.getOneOrder(orderId),
    {
      keepPreviousData: false,
      enabled: orderId !== '',
    },
  )

  if (!order) return null

  const getOrderIdFormatted = (orderIdentifier?: string) => {
    if (orderIdentifier && orderIdentifier.length > 5) {
      return orderIdentifier.slice(-5)
    }
    return 'NA'
  }

  const onDeleteOrder = async (orderIdToDelete: string) => {
    if (!orderIdToDelete) return
    setIsLoading(true)
    try {
      await OrderClientService.deleteDirectOrder(orderIdToDelete)
      await queryClient.invalidateQueries({
        queryKey: ['producer_orders'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['current_direct_carts'],
      })
      navigation.navigate('ProducerOrderList')
    } catch (error) {
      setErrorMessage('Erreur lors de la suppression de la commande')
    } finally {
      setIsLoading(false)
    }
  }

  const onClickCartBatch = (cartBatchId: string, cartId: string) => {}

  const onClickDeliveryNote = (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.documentId || !deliveryNote.accessToken) return

    Linking.openURL(
      DirectusUtil.getDocumentDownloadUrlFromId(deliveryNote.documentId, deliveryNote.accessToken),
    )
  }

  const renderCarts = () => {
    if (!order.cart?.cartBatchs || order.cart?.cartBatchs.length < 1) return null
    return (
      <View>
        <SectionTitle title={`Commande #${getOrderIdFormatted(order.id)}`} />
        <Typography.BodySmall style={{ marginLeft: `${theme.spacingUnit * 2}px` }}>
          Validée le {FormatUtils.formatDate(order.createdDate, 'FullDate')} à{' '}
          {FormatUtils.formatDate(order.createdDate, 'Time')}
        </Typography.BodySmall>
        {order.cart?.cartBatchs?.map((cartBatch) => (
          <BatchCartCard
            key={cartBatch.id}
            cartBatch={cartBatch}
            onClick={() => onClickCartBatch(cartBatch.id, order.cart?.id || '')}
            withProducer
            userRole={UserEnums.RoleEnums.CARRIER}
          />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <SectionTitle title="Actions" />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {order.type === OrderType.DIRECT && (
          <CardSettings
            title="Une annulation ?"
            description="Le client souhaite annuler sa commande"
          >
            <Button
              fullWidth
              colorName="color-grey"
              label=" Supprimer"
              loading={isLoading}
              onPress={() => onDeleteOrder(order.id || '')}
              disabled={group?.type === GroupEnums.GroupTypeEnum.DELIVERY_PRODUCERS}
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir supprimer cette commande ?"
            />
          </CardSettings>
        )}
        <Spacer size={2.5} axis={Spacer.AxisEnum.VERTICAL} />
        <OrderHistory order={order} />
      </View>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Commande #${getOrderIdFormatted(order.id)}`}
            buttonRight={
              <Typography.BodySmall>
                {FormatUtils.capitalize(FormatUtils.formatDate(order.createdDate, 'FullDate'))}
                {'\n'}
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(order.createdDate, 'StartTime+EndTime'),
                )}
              </Typography.BodySmall>
            }
          />
          <OrderClientTopBox order={order} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {errorMessage ? (
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          ) : null}
          {order.cart?.cartBatchs && order.cart?.cartBatchs.length > 0 ? (
            renderCarts()
          ) : (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

              <Typography.Body colorName="color-danger" align="center">
                Aucune commande à livrer pour ce {FormatUtils.getLabelFromClientType(1)}
              </Typography.Body>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            </>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          {order.type === OrderType.DIRECT && (
            <>
              {(order.deliveryNotes || []).length > 0 && (
                <Typography.SectionTitle colorName="text-dark-3">
                  Bons de livraison
                </Typography.SectionTitle>
              )}
              {(order.deliveryNotes || []).map((deliveryNote) => (
                <TourDeliveryNoteCard
                  key={deliveryNote.id}
                  deliveryNote={deliveryNote}
                  onClick={onClickDeliveryNote}
                  issuer={deliveryNote.issuer}
                  recipient={deliveryNote.recipient}
                  isCarrier={false}
                />
              ))}
            </>
          )}
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerDirectOrderScreen
