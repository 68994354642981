import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import BillingProducerNavigationStackParamList from './BillingProducerNavigation.model'
import ProducerBillingDeliveryNotesScreen from '../../../screens/Producer/ProducerBillingDeliveryNotes'
import ProducerBillingScreen from '../../../screens/Producer/ProducerBilling'
import HeaderHome from '../../components/HeaderHome'
import ProducerClientsScreen from '../../../screens/Producer/ProducerClients'
import ProducerClientScreen from '../../../screens/Producer/ProducerClient'
import ProducerBillingPaymentConfigurationScreen from '../../../screens/Producer/ProducerBillingPaymentsConfiguration'
import EditInvoiceScreen from '../../../screens/User/screens/UserEditInvoice'
import CreateInvoiceScreen from '../../../screens/User/screens/UserCreateInvoice'
import ListInvoiceScreen from '../../../screens/User/screens/UserListInvoice'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import InvoiceStatementListScreen from '../../../screens/User/screens/UserListInvoiceStatement'
import EditInvoiceStatementScreen from '../../../screens/User/screens/UserEditInvoiceStatement'

import ProducerDirectCartAddScreen from '../../../screens/Producer/ProducerDirectCartAddScreen'
import ProducerDirectCartCatalogScreen from '../../../screens/Producer/ProducerDirectCartCatalog'
import ProducerDirectCartUpdateScreen from '../../../screens/Producer/ProducerDirectCartUpdateScreen'
import ProducerDirectOrderScreen from '../../../screens/Producer/ProducerDirectOrder'
import ProducerOrderListScreen from '../../../screens/Producer/ProducerOrderList'
import ProducerUpdateClientScreen from '../../../screens/Producer/ProducerUpdateClient'
import ProducerAddClientScreen from '../../../screens/Producer/ProducerAddClient'

const Stack = createStackNavigator<BillingProducerNavigationStackParamList>()

const BillingProducerNavigation = () => {
  const { isCurrentUserRootAdmin } = useAuthenticationContext()

  return (
    <Stack.Navigator
      initialRouteName="ProducerBilling"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProducerBilling"
        component={ProducerBillingScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Reporting Prix',
        }}
      />
      <Stack.Screen
        name="ProducerBillingDeliveryNotes"
        component={ProducerBillingDeliveryNotesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: 'Ajouter un relevé de prix',
        }}
      />
      <Stack.Screen
        name="ProducerClients"
        component={ProducerClientsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: '',
        }}
      />
      <Stack.Screen
        name="ProducerBillingPaymentConfiguration"
        component={ProducerBillingPaymentConfigurationScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: 'Configuration des paiements',
        }}
      />
      <Stack.Screen
        name="ProducerClient"
        component={ProducerClientScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerClients" />,
          title: '',
        }}
      />
      <Stack.Screen
        name="InvoiceStatementList"
        component={InvoiceStatementListScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: 'Relevés',
        }}
      />

      <Stack.Screen
        name="EditInvoiceStatement"
        component={EditInvoiceStatementScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="InvoiceStatementList" />,
          title: 'Modifier un relevé',
        }}
      />
      <Stack.Screen
        name="ProducerOrderList"
        component={ProducerOrderListScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: 'Commandes',
        }}
      />
      <Stack.Screen
        name="ProducerDirectOrder"
        component={ProducerDirectOrderScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerOrderList" />,
          title: 'Détails de la commande',
        }}
      />
      <Stack.Screen
        name="ProducerDirectCartAdd"
        component={ProducerDirectCartAddScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: 'Ajouter une commande',
        }}
      />
      <Stack.Screen
        name="ProducerDirectCartCatalog"
        component={ProducerDirectCartCatalogScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: 'Catalogue',
        }}
      />
      <Stack.Screen
        name="ProducerUpdateClient"
        component={ProducerUpdateClientScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerClient" />,
          title: 'Modifier un client',
        }}
      />
      <Stack.Screen
        name="ProducerAddClient"
        component={ProducerAddClientScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerClients" />,
          title: 'Ajouter un client',
        }}
      />

      <Stack.Screen
        name="ProducerDirectCartUpdate"
        component={ProducerDirectCartUpdateScreen}
        options={{
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerDirectCartCatalog" />,
          title: 'Modifier la commande',
        }}
      />

      {isCurrentUserRootAdmin() && (
        <>
          <Stack.Screen
            name="EditInvoice"
            component={EditInvoiceScreen}
            options={{
              headerShown: true,
              header: () => <Header title="" goBackFallback="ProducerBilling" />,
            }}
          />
          <Stack.Screen
            name="CreateInvoice"
            component={CreateInvoiceScreen}
            options={{
              headerShown: true,
              header: () => <Header title="" goBackFallback="ProducerBilling" />,
            }}
          />
          <Stack.Screen
            name="ListInvoice"
            component={ListInvoiceScreen}
            options={{
              headerShown: true,
              header: () => <Header title="" goBackFallback="ProducerBilling" />,
            }}
          />
        </>
      )}
    </Stack.Navigator>
  )
}

export default BillingProducerNavigation
