import { Cart } from '../../../domain/Cart'
import { CatalogCart, CatalogResponse } from '../../../domain/catalog'
import { Pagination } from '../../../domain/Pagination'
import ApiService from '../../api.service'

interface GetDirectCatalogParams {
  cartId: string
  search?: string
  limit?: number
  page?: number
  after?: Date
  before?: Date
}

const getDirectCatalog = async ({
  cartId,
  search,
  limit = 200,
  page = 1,
  after,
  before,
}: GetDirectCatalogParams): Promise<CatalogResponse> => {
  const response = await ApiService.get('/direct/cart-batch/catalog', {
    cartId,
    search,
    limit,
    page,
    after,
    before,
  }).catch((error) => {
    throw error
  })
  return response
}

const getAllCartBatchs = async (
  groupId?: string,
  tourId?: string,
  clientId?: string,
  producerId?: string,
  before?: Date,
  after?: Date,
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{ data: DeliveryNotesCartBatchs[]; pagination: Pagination; stats: any }> => {
  const response = await ApiService.get(`/cart/producer/${producerId}/cartBatchs`, {
    clientId: clientId || undefined,
    producerId: producerId || undefined,
    groupId: groupId || undefined,
    before: before || undefined,
    after: after || undefined,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })

  return response
}
const create = async (clientId: string, producerId: string, dto: any): Promise<Cart> => {
  const response = await ApiService.post(
    `/cart/producer/${producerId}/client/${clientId}`,
    dto,
  ).catch((error) => {
    throw error
  })
  return response
}

const createUpdateOrder = async (clientId: string, idCart: string): Promise<Cart> => {
  const response = await ApiService.post(`/cart/${idCart}/client/${clientId}`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const getOneCart = async (cartId?: string): Promise<Cart | null> => {
  if (!cartId) return null
  const response = await ApiService.get(`/cart/${cartId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const addDirectCart = async (body: {
  clientId: string
  deliveryStart: string
  deliveryDeposit: string
}): Promise<Cart> => {
  const response = await ApiService.post(`/direct/cart`, body)
  return response
}

interface GetCurrentDirectCartsParams {
  producerId: string
  limit?: number
  page?: number
  after?: string
  before?: string
}

interface GetCurrentDirectCartsResponse {
  data: CatalogCart[]
  pagination: Pagination
}

const getCurrentDirectCarts = async ({
  producerId,
  limit = 10,
  page = 1,
  after,
  before,
}: GetCurrentDirectCartsParams): Promise<GetCurrentDirectCartsResponse> => {
  const response = await ApiService.get(`/direct/cart/producer/${producerId}`, {
    limit,
    page,
    after,
    before,
  })
  return response
}

const CartProducerService = {
  getAllCartBatchs,
  createUpdateOrder,
  getOneCart,
  create,
  addDirectCart,
  getDirectCatalog,
  getCurrentDirectCarts,
}

export default CartProducerService
