import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { useTheme } from 'styled-components/native'
import { CardPageLink, SectionTitle } from '../../../components'
import PageTitle from '../../../components/PageTitle'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'

const ProducerBillingScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { isCurrentUserRootAdmin } = useAuthenticationContext()

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Gestion" />
        <CardPageLink
          label="Commandes"
          icon="arrow-right"
          onClick={() => navigation.navigate('ProducerOrderList')}
        />
        <CardPageLink
          label="Clients"
          icon="arrow-right"
          onClick={() => navigation.navigate('ProducerClients', {})}
        />
        <SectionTitle title="Facturation et Finances" />
        <CardPageLink
          label="Bons de livraison"
          icon="arrow-right"
          onClick={() => navigation.navigate('ProducerBillingDeliveryNotes', {})}
        />
        {isCurrentUserRootAdmin() && (
          <CardPageLink
            label="Factures"
            icon="arrow-right"
            onClick={() => navigation.navigate('ListInvoice')}
          />
        )}
        <CardPageLink
          label="Relevés"
          icon="arrow-right"
          onClick={() => navigation.navigate('InvoiceStatementList')}
        />
        <SectionTitle title="Paramétrage" />
        <CardPageLink
          label="Configuration des paiements"
          icon="arrow-right"
          onClick={() => navigation.navigate('ProducerBillingPaymentConfiguration', {})}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerBillingScreen
